import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { addDays } from 'date-fns';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {

  getTomorrow(): Date {
    return addDays(new Date(), 1);
  }


  listOfColumn = [
    {
      title: 'From Date',
      compare: (a: any, b: any) => a.fromDate.localeCompare(b.fromDate),
      priority: 1,
      isFixed: true,
    }, {
      title: 'To Date',
      compare: (a: any, b: any) => a.toDate.localeCompare(b.toDate),
      priority: 2,
      isFixed: false,
    }, {
      title: 'Campaign Name',

      compare: false,
    },
    {
      title: 'Status',
      compare: false
    },
     {
      title: 'Download Report Link',
      compare: false,
    }
  ];

  listCampaignNames: any;
  listOfData: any;
  downloadLink: any;
  pageNumber: number = 1;

  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();
  datePipe: any;

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private reportService: ReportService) {

    }



    disabledDate = (current: Date): boolean => {
      // Calculate tomorrow's date
      const tomorrow = this.getTomorrow();
      tomorrow.setHours(0, 0, 0, 0); // Set the time to the beginning of the day
    
      // Enable the date if it's tomorrow or a future date
      return current.getTime() >= tomorrow.getTime();
    };
    





  ngOnInit(): void {
    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
      campaignName: ['', Validators.required]
    });
    const previousDate = new Date();
  previousDate.setDate(previousDate.getDate() );
    this.validateForm.patchValue({
      rangePicker: [previousDate, previousDate]
    });
    this.viewGeneratedReport();
    this.getCampaingNameList();

  }

  onChange(result: Date[]): void {
    console.log('onChange: ', result);
    this.getCampaingNameList(result[0], result[1])
  }



  submitForm(): void {
    if (!this.validateForm.get('campaignName')?.value) {
      return;
    }
    const selectedRange = this.validateForm.get('rangePicker')?.value;
    const differenceInDays = differenceInCalendarDays(selectedRange[1], selectedRange[0]);
    if (differenceInDays < 0 || differenceInDays > 6) {
      this.utilsService.showErrorMsg('Date Range Select Any 7 Days');
      return;
    }

    let form = this.validateForm.value;
    if (form.rangePicker) {
      let params = {
         campaignId: (this.validateForm.get('campaignName')?.value == "All")?0:form.campaignName,
         campaignName: this.validateForm.get('campaignName')?.value == "All"?"All":this.listCampaignNames.find((item:any)=>item.campaignId==this.validateForm.get('campaignName')?.value).campaignName,
         extension: 'csv'
      };

      this.generateReport(form.rangePicker[0], form.rangePicker[1], params);
    }
  }







  viewGeneratedReport(from: any = this.today, to: any = this.today, page: any = this.pageNumber) {
    let params = {
      "username": this.storageService.username,
      "fromDate": this.utilsService.dateFormat(from),
      "toDate": this.utilsService.dateFormat(to),
      // campaignName: '',
      // campaignId: '',
      // pageNumber: page
    }

    this.reportService.getGeneratedReports(params).subscribe(data => {
      this.listOfData = data.data.grid;
    })
  }

  generateReport(from: any = this.today, to: any = this.today, extras?: any) {
    let params = {
      "username": this.storageService.username,
      "fromDate": this.utilsService.dateFormat(from),
      "toDate": this.utilsService.dateFormat(to),
    }
    var body = { ...params, ...extras }; // this is the answer

    this.reportService.generateDownlaodReport(body).subscribe(data => {
      this.listCampaignNames = data.data.grid;
      this.message.create('success', data.message);
      this.viewGeneratedReport();
    })
  }

  getCampaingNameList(from: any = this.today, to: any = this.today) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to)
    }

    this.reportService.getCampaignReport(params).subscribe(data => {
      this.listCampaignNames = data.data.grid;
    })
  }


  downloadFilee() {
    this.reportService.downloadFilee(this.downloadLink);
  }

  ngOnDestroy() {
  }


}