import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '../core/user/user.types';

@Injectable({
  providedIn: 'root'
})
export class UserService {
//   private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        localStorage.setItem('userData', JSON.stringify(value));
        this.user.next(value);
    }

    get user$(): Observable<User> {
        let userData: User = JSON.parse(localStorage.getItem('userData') ?? '');
        return of(userData);
        // return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getUserName(): string {

        if (localStorage.getItem('userData')) {
            let userData = JSON.parse(localStorage.getItem('userData') ?? '');

            return userData.username;
        } else {
            return '';
        }
        //  this.user$.subscribe(data => {
        //     // return data.name;
        //     next: (value) => console.log("The value is: ", value)
        // });
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {

        // return this._user;

        let test: User = {
            id: "2342442",
            name: "apidemo",
            email: "email.com",
            avatar: "",
            status: "online",
            next: function (value: User): unknown {
                throw new Error('Function not implemented.');
            }
        }

        return of(test);

        // let self = this;
        // return this._httpClient.get<User>('api/common/user').pipe(
        //     tap((user) => {
        //         let testUser = {
        //             // authToken: "1a2b3c4d5e6f7g8h9i",
        //             // lastLoginIp: "113.30.176.32",
        //             // lastLoginTime: "2022-02-13 05:42:23",
        //             // role: "client",
        //             // userPrivileges: ['SHOW_CAMPAIGN_REPORT'],
        //             // username: "apidemo",

        //             id: "2342442",
        //             name: "apidemo",
        //             email: "email.com",
        //             avatar: "",
        //             status: "online"

        //         }
        //         this._user.next(testUser);
        //     })
        // );
    }

    getPrivilage(): string {
        if (localStorage.getItem('userData')) {
            let userData = JSON.parse(localStorage.getItem('userData') ?? '');
            return userData.userPrivileges;
        } else {
            return '';
        }
    }
    
    // /**
    //  * Update the user
    //  *
    //  * @param user
    //  */
    // update(user: User): Observable<any>
    // {
    //     return this._httpClient.patch<User>('api/common/user', {user}).pipe(
    //         map((response) => {
    //             this._user.next(response);
    //         })
    //     );
    // }
    getUserID(): string {
        let userData = JSON.parse(localStorage.getItem('userData') ?? '');
        return userData.userId;
    }
}
