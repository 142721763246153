import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AnimationBuilder } from '@angular/animations';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.webtool';
import { UserService } from 'src/app/sevices/user.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ReportService } from 'src/app/services/reports/report.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  profileForm!:FormGroup
  selected: boolean=false;
  profileModule: any;
  profileData={};
  passwordData={};
  emailData={};
  mobileData={};
  OrganisationData={};
  DepartmentData={};
 
  
  //  parameters = {
   
  //   profileForm1: '',
  //     Username: '',
  //     Password: '' ,
  //     Email: '',
  //     mobileNumber:    '',
  //     Organisation: '',
  //     Department:   '',
  //     }

 
constructor( private _httpClient: HttpClient, private fb: FormBuilder,private reportService:ReportService ,

  private _builder: AnimationBuilder ) {
  this.profileForm = fb.group({    
    profileForm1: ['', [Validators.required]],
    Username: ['',[Validators.required]], 
    password: ['',[Validators.required]] ,
    Email :       ['',[Validators.required]],
    mobileNumber:      ['',[Validators.required]],
    Organisation:  ['',[Validators.required]],
    Department:       ['',[Validators.required]],
  })

}
ngOnInit(): void {


    this.reportService.profile().subscribe(data => {
      this.profileData=data.data.user.username
      console.log(this.profileData)
   
      this.passwordData=data.data.user.password
      console.log(this.passwordData)
   
      this.emailData=data.data.user.emailID
      console.log(this.emailData)
   
      this.mobileData=data.data.user.mobileNumber
      console.log(this.mobileData)
   
      
      this.OrganisationData=data.data.user.organization
      console.log(this.OrganisationData)
   
      this.DepartmentData=data.data.user.department
      console.log(this.DepartmentData)
   
  
    })
  }




}




