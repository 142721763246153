import { Component,Input,HostListener,Output,OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap, Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APP_NAME } from 'src/environments/environment';
import { AuthGuardGuard } from './services/auth-guard/auth-guard.guard';
import { StorageService } from './services/storage/storage.service';
import { Router } from '@angular/router';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
@Input() sidebarshow:boolean=false;
isCollapsed = false;
  // isCollapsed: boolean = this.sidebarshow;
  // isCollapsed: boolean = true;

  title = 'gui-2';
  pageName: string = '';
  appName = APP_NAME;
  sidebar:any;
  isAuthenticated: boolean = false;
  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,

    private authGuard: AuthGuardGuard) {
    this.authGuard.loggedInUser.subscribe(isLoggedIn => {
      this.isAuthenticated = isLoggedIn;
    })


  }
  onClick(){
   (this.sidebar==true)?this.sidebar=false:this.sidebar=true;
  }

  getSession(): boolean {
    let token = this.storageService.accessToken;
    return token ? true : false;
  }

  ngOnInit(): void {
    this.checkScreenSize()
    console.log(this.sidebarshow)
    // this.clearAllCookies();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data =>
      // console.log('data', data)
      this.pageName = data['pageName']
    )

    // Check the token isAvailable
    this.isAuthenticated = this.authGuard.isTokenAvailable()
    if (!this.isAuthenticated) {
      this.router.navigateByUrl('/')
    }
  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
    this.isCollapsed = this.isSmallScreen; // Set isCollapsed to true if screen width is less than or equal to 768px
  }
  
  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    console.log('Menu is collapsed:', this.isCollapsed);
  }
  // clearAllCookies() {
  //   var cookies = document.cookie.split(";");
  //   for (var i = 0; i < cookies.length; i++) {
  //       var cookie = cookies[i];
  //       var eqPos = cookie.indexOf("=");
  //       var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //       document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  //   }


  // }
}
