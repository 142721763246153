<h4 nz-typography>Search Contact</h4>
<form nz-form [formGroup]="contactsForm" nzLayout="vertical">
    <div nz-row [nzGutter]="24" nzAlign="middle">
        <div nz-col>
            <nz-form-item>
                <nz-form-label>Select Group</nz-form-label>
                <nz-form-control style="width: 200px;">
                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Group" formControlName="groupName">
                        <nz-option nzLabel="{{item.groupName}} ({{item.numbersInGroupCount}})"
                            [nzValue]="item.groupName" *ngFor="let item of listOfGroups">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <br>
        <div nz-col>
            <nz-form-item>
              <br>
                <nz-form-label >Mobile Number</nz-form-label>
                <nz-form-control>
                    <input type="text" nz-input formControlName="contactNumber" placeholder="Search Mobile Number" />
                </nz-form-control>
                <i><small>Note :Search with 91</small></i>
            </nz-form-item>

        </div>
        <div nz-col>
            <nz-form-control>
                <button nz-button nzType="primary" (click)="seachData()" [disabled]="!contactsForm.valid">
                    <span nz-icon nzType="search"></span>
                    Search
                </button>
            </nz-form-control>
        </div>
        <div nz-col>
            <nz-form-control>
                <button nz-button nzType="primary" (click)="listAllContacts()">
                    <span nz-icon nzType="unordered-list"></span>
                    All Contacts
                </button>
            </nz-form-control>
        </div>
    </div>
</form>

<div nz-row [nzGutter]="24">
    <div nz-col>
        <!-- <button nz-button nzType="default" nzDanger (click)="deleteGroup()">Delete Selected</button> -->
    </div>
    <div nz-col>
        <button nz-button nzType="primary" (click)="downloadFile()" *ngIf="downloadLink">
            <span nz-icon nzType="download"></span>
            Export
        </button>
    </div>
</div>

<br />
<app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn" [type]="'contacts'"
    (deleteTriggred)="selectedContacts = $event" [pagination]="true"></app-simple-table>
