<div nz-row nzJustify="center" nzGutter="48">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
        <h4 nz-typography>Upload Contact</h4>

        <div class="filled-border">
            <small>
                * you can upload any .txt file which carry the
                information in the following text format :-
                <br>
                <span nz-typography><mark><b>Mobile Number, Name</b></mark></span><br>
                919998......, Abhay<br>
                8435......, Sanjay<br>
                <b>Note -</b> Mobile number can be uploaded with or without
                91<br>
            </small>
        </div>
        <br>

        <div class="form-input">
            <form nz-form [formGroup]="uploadBlacklist" nzLayout="vertical">
                <div nz-row [nzGutter]="24" nzAlign="middle">
                    <nz-form-label nzRequired>Select Group</nz-form-label>
                    <nz-form-control style="width: 200px;">
                      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Group" formControlName="groupName">
                        <nz-option [nzLabel]="item.groupName" [nzValue]="item.groupId" *ngFor="let item of listOfGroups">
                        </nz-option>
                      </nz-select>
                      <!-- Display validation error message -->
                      <div *ngIf="uploadBlacklist.get('groupName')?.invalid && uploadBlacklist.get('groupName')?.touched">
                        <small class="error-message">Please select a group.</small>
                      </div>
                    </nz-form-control>
                </div>
<br>
<div nz-row [nzGutter]="24" nzAlign="middle">
                <nz-form-item style="margin-left: 3%;">

                    <nz-form-control>
                      <nz-form-label nzRequired >File</nz-form-label>
                        <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" style="margin-left: 78px;">
                            <button nz-button>
                                <span nz-icon nzType="upload"></span>
                                Select File
                            </button>
                        </nz-upload>
                        <i><small>Note : Only .txt allowed</small></i>
                    </nz-form-control>
                </nz-form-item>
</div>

                <button nz-button nzType="primary" (click)="uploadBulk()">Upload Number List</button>
                <button nz-button (click)="uploadreset()">Clear</button>

            </form>
        </div>
    </div>
    <div nz-col></div>
    <div nz-col></div>
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
        <h4 nz-typography>Add New Contact</h4>
        <div class="form-input">
            <form nz-form [formGroup]="addContactsForm" nzLayout="vertical">

                <nz-form-item>
                    <nz-form-label nzRequired>Select Group</nz-form-label>
                    <nz-form-control style="width: 200px;">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Group" formControlName="groupName">
                            <nz-option [nzLabel]="item.groupName" [nzValue]="item.groupName"
                                     *ngFor="let item of listOfGroups">

                                       </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Name</nz-form-label>
                    <nz-form-control [nzLg]="{ span: 16 }">
                        <input type="text" nz-input formControlName="contactName" placeholder="Enter Name" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Mobile Number</nz-form-label>
                    <nz-form-control [nzLg]="{ span: 16 }">
                        <input type="text" nz-input formControlName="contactNumber" placeholder="Enter Mobile Number" />
                    </nz-form-control>
                    <i><small>Note : without 91</small></i>
                </nz-form-item>

                <button nz-button nzType="primary" (click)="submitAddContactForm()">Submit</button>
                <button nz-button (click)="resetAddContactForm()">Reset</button>
            </form>
        </div>
    </div>

</div>
