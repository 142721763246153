import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  get(arg0: string) {
    throw new Error('Method not implemented.');
  }
  username(arg0: string, username: any) {
    throw new Error('Method not implemented.');
  }

  constructor(
    private _storageService: StorageService,
    private _httpClient: HttpClient
  ) { }

  getSenderIdByMsgType(msgType: string = 'others', msgSubType?: string): Observable<any> {
    let cred = {
      loggedInUserName: this._storageService.username,
      messageType: msgType === null ? 'others' : msgType,
      messageSubType: msgSubType
    };
    return this._httpClient.post(environment.getSenderIds, cred);
  }

  saveTemplate(params: any): Observable<any> {
    return this._httpClient.post(environment.saveContentTemplate, params);
  }

  uploadTemplate(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadDltDataFile, params);
  }
  uploadCustomDltDataaFile(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadCustomFile, params);
  }

}
