<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<div nz-modal-header>

<div nz-modal-content>
  <form [formGroup]="editTemplateForm" class="horizontal-form">

    <div class="mb-3 row">
        <label for="entityId" class="col-sm-2 col-form-label">Entity ID</label>
        <div class="col-sm-10">
          <input id="entityId" type="text" formControlName="entityId" class="form-control" (input)="onEntityIdChange()">
          <div *ngIf="editTemplateForm.get('entityId')?.hasError('nonNumeric') && (editTemplateForm.get('entityId')?.touched || submitted)"
               class="text-danger">
            Enter Only number.
          </div>
        </div>
      </div>

      <!-- <div class="mb-3 row">
        <label for="senderId" class="col-sm-2 col-form-label">Sender ID</label>
        <div class="col-sm-10">
          <input id="senderId" type="text" formControlName="senderId" class="form-control" >
          <div *ngIf="editTemplateForm.get('senderId')?.hasError('maxLength') || editTemplateForm.get('senderId')?.hasError('minLength')"
          class="text-danger">
       Sender ID must be exactly 6 characters.
     </div>
        </div>
      </div> -->
      <div class="mb-3 row">
        <label for="senderId" class="col-sm-2 col-form-label">Sender ID</label>
        <div class="col-sm-10">
          <input
            id="senderId"
            type="text"
            formControlName="senderId"
            class="form-control"
            minlength="6"
            maxlength="6"
          >
          <div
            *ngIf="editTemplateForm.get('senderId')?.hasError('minlength') || editTemplateForm.get('senderId')?.hasError('maxlength')"
            class="text-danger"
          >
            Sender ID must be exactly 6 characters.
          </div>
        </div>
      </div>




      <div class="mb-3 row">
        <label for="templateId" class="col-sm-2 col-form-label">Template ID</label>
        <div class="col-sm-10">
          <input id="templateId" type="text" formControlName="templateId" class="form-control" (input)="onEntityIdChangee()">
          <div *ngIf="editTemplateForm.get('templateId')?.hasError('nonNumeric') && (editTemplateForm.get('templateId')?.touched || submitted)"
               class="text-danger">
            Enter Only number.
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="templateText" class="col-sm-2 col-form-label">Template Text</label>
        <div class="col-sm-10">
          <textarea id="templateText" type="text" formControlName="templateText" class="form-control">
          </textarea>
        </div>
      </div>

      <div class="form-group row mt-4" style="margin-left: 20%; border-top: 2px black; ">
        <div class="offset-sm-2 col-sm-10" >
          <button style="margin-left: 7%;" nz-button  nzType="primary"  [disabled]="isSaving"  (click)="editFormClick()">Save</button>
          <button nz-button (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
</div>

