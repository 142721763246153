import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-scheduled-campaign',
  templateUrl: './scheduled-campaign.component.html',
  styleUrls: ['./scheduled-campaign.component.scss']
})
export class ScheduledCampaignComponent implements OnInit {

  listOfColumn = [
    {
      title: 'Campaign Type',
      compare: null,
      priority: false
    },
    {
      title: 'Campaign Name',
      compare: (a: any, b: any) => a.totalRequest - b.totalRequest,
      priority: 1
    },
    {
      title: 'Scheduled Date/Time',
      compare: (a: any, b: any) => a.totalRejected - b.totalRejected,
      priority: 2
    },
    {
      title: 'Sender Id',
      compare: (a: any, b: any) => a.totalSubmit - b.totalSubmit,
      priority: 3
    },
    {
      title: 'Message Text',
      compare: (a: any, b: any) => a.massageText - b.massageText,
      priority: 4
    },
    {
      title: 'Message Count',
      compare: (a: any, b: any) => a.totalDelivered - b.totalDelivered,
      priority: 4
    }, 
    {
      title: 'Action',
      compare: (a: any, b: any) => a.totalFailed - b.totalFailed,
      priority: 5
    }
  ];

  listOfData: any[] = [];
  downloadLink: any;
  pageNumber: number = 1;
  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();
  loggedInUser: any;

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private campaignService: CampaignService,
    private modal: NzModalService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  disabledDate = (current: Date): boolean => false;
    // Can not select days before today and today
    // differenceInCalendarDays(current, this.today) > 0;

  ngOnInit(): void {
    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
    });

   
  }

  onChange(result: Date[]): void {
    console.log('From: ', result[0], ', to: ', result[1]);
  }

  submitForm(): void {
    let form = this.validateForm.value;
    if (form.rangePicker) {
      this.getAllScheduledCampaign(form.rangePicker[0], form.rangePicker[1])
      
    }
  }

  getAllScheduledCampaign(from: any = this.today, to: any = this.today, page: any = this.pageNumber) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to),
      campaignType: "All"
    }

    this.reportService.getAllCampaignReport(params).subscribe(data => {
      this.listOfData = data.data.consolidateCampaignList;
    })
  }




  // deleteRow(index: number): void {
  //   console.log('Delete button clicked in child component. Index:', index);
  
  //   if (index >= 0 && index < this.listOfData.length) {
  //     const deletedData = this.listOfData[index];
  
  //     // Log the deletedData to ensure it is not undefined
  //     console.log('Deleted Data:', deletedData);
  
  //     // Extract the first word from campaignType
  //     const operationValue = deletedData.campaignType.split(' ')[0];
  
  //     this.campaignService.deletescheduleCampaignReport({
  //       "loggedInUserName": this.storageService.username,
  //       campaignId: deletedData.campaignId,
  //       operation: operationValue  // Use the extracted value as the operation
  //     }).subscribe(
  //       (data) => {
  //         console.log('API response:', data);
  //         // Optionally, you can perform additional actions upon successful deletion
  
  //         // Update the listOfData if needed (remove the deleted row)
  //         this.listOfData.splice(index, 1);
  //       },
  //       (error) => {
  //         console.error('API error:', error);
  //         // Optionally, you can handle the error
  //       }
  //     );
  //   } else {
  //     console.warn('Index out of range or invalid.');
  //   }
  // }


  deleteRow(data: any): void {
    console.log('Delete button clicked in parent component. Data:', data);
  
    // Extract the necessary values from the data object
    const operationValue = data.campaignType.split(' ')[0];
  
    this.campaignService.deletescheduleCampaignReport({
      "loggedInUserName": this.storageService.username,
      campaignId: data.campaignId,
      operation: operationValue
    }).subscribe(
      (responseData) => {
        console.log('API response:', responseData);
        // Optionally, you can perform additional actions upon successful deletion
  
        // Update the listOfData by creating a new array without the deleted item
        this.listOfData = this.listOfData.filter(item => item !== data);
  
        // Check the result from the API and show success or error message
        if (responseData.result === 'Success') {
          this.utilsService.showSuccessMsg(responseData.message);
  
          // Reload the table with the same date range
          const form = this.validateForm.value;
          if (form.rangePicker) {
            this.getAllScheduledCampaign(form.rangePicker[0], form.rangePicker[1]);
          }
        } else {
          this.utilsService.showErrorMsg(responseData.message);
        }
      },
      (error) => {
        console.error('API error:', error);
        // Optionally, you can handle the error
  
        // Set the API message for error case
        this.utilsService.showErrorMsg('An error occurred during the API call.');
      }
    );
  }
  

  
  

  ngOnDestroy() {
  }
}
