<!-- <div nz-row nzJustify="space-between" nzGutter="48">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }"> -->
        <h4 nz-typography>Quick Message</h4>

        <div class="form-input">
            <form nz-form [formGroup]="groupCampaignForm">
                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Campaign Name</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1" [nzValidatingTip]="note" [nzErrorTip]="nameErrorTpl">
                        <input nz-input placeholder="" formControlName="campaignName" />
                        <ng-template #nameErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">required
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">{{note}}</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Message Encoding</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group nzButtonStyle="solid" formControlName="msgType">
                            <label nz-radio-button nzValue="plain">Plain Text</label>
                            <label nz-radio-button nzValue="unicode">Unicode</label>
                            <label nz-radio-button nzValue="FL">Flash</label>
                            <label nz-radio-button nzValue="FU">Flash Unicode</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Message Part</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group nzButtonStyle="solid" formControlName="msgPart"
                            [ngModel]="messageText | creditCount:'checkPart'">
                            <label nz-radio-button nzValue="single">Singlepart</label>
                            <label nz-radio-button nzValue="multi">Multipart</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Select Group</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Group" formControlName="groupName">
                            <nz-option [nzLabel]="item.groupName" [nzValue]="item.groupName"
                                *ngFor="let item of listOfGroups">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Message Type</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group nzButtonStyle="solid" formControlName="serviceType" [(ngModel)]="messageType"
                            (ngModelChange)="getSenderIdsByMsgType($event)">
                            <label nz-radio-button nzValue="trans">Transactional</label>
                            <label nz-radio-button nzValue="promo">Promotional</label>
                            <label nz-radio-button nzValue="others">Service</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="messageType === 'others'">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Service Type</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group nzButtonStyle="solid" formControlName="serviceSubType"
                            (ngModelChange)="getSenderIdsByMsgType(messageType,$event)">
                            <label nz-radio-button nzValue="service-implicit">Service Implicit</label>
                            <label nz-radio-button nzValue="service-explicit">Service Explicit</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Sender ID</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Sender Id" formControlName="senderId"
                            (ngModelChange)="getTemplates(messageType,$event)" [(ngModel)]="senderIdSelected">
                            <nz-option [nzLabel]="item.senderId" [nzValue]="item.senderId"
                                *ngFor="let item of listOfSenderIds">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Content Template Name</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Template"
                            formControlName="templateName" autocomplete="off"
                            (ngModelChange)="templateSelected = $event;messageText = templateSelected?.templateText">
                            <nz-option [nzLabel]="item.templateTitle" [nzValue]="item"
                                *ngFor="let item of listOfTemplates">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Schedule Message</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group formControlName="isScheduledSMS" [(ngModel)]="isScheduledSMS">
                            <label nz-radio nzValue="yes">Yes</label>
                            <label nz-radio nzValue="no">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="isScheduledSMS === 'yes'">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Schedule Date & Time</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-date-picker
                        nzFormat="yyyy-MM-dd HH:mm"
                        [nzDisabledDate]="disabledDate"
                        [nzDisabledTime]="disabledDateTime"
                        [(ngModel)]="selectedDate"
                        formControlName="scheduleDateTime"
                        [nzShowNow]="false"
                        [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue, nzFormat: 'HH:mm'  }">
                           </nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Message Text</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-textarea-count>
                            <!-- [ngModel]="templateSelected?.templateText" -->
                            <textarea rows="4" #textArea formControlName="msgText" nz-input (input)="msgLength()" placeholder="Message Text"
                                [(ngModel)]="messageText"></textarea>
                        </nz-textarea-count>
                        <a style="margin-left: 1px;">
                          {{msgTextLength ==0? messageText.length:msgTextLength}} characters | {{messageText | creditCount }} SMS Credit
                      <br />
                    </a>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Is ShortUrl Selected</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-radio-group formControlName="isShortUrlSelected" [(ngModel)]="isShortUrlSelected">
                            <label nz-radio nzValue="Y">Yes</label>
                            <label nz-radio nzValue="N">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="isShortUrlSelected === 'Y'">
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Short URL Name</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <nz-select nzMode="tags" nzPlaceHolder="Select Short URL Name" formControlName="shortUrlName"
                            (ngModelChange)="onShortUrlSelect($event,textArea)">
                            <nz-option *ngFor="let option of listOfShortUrl" [nzLabel]="option.name"
                                [nzValue]="option.name"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Entity ID</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <input  nz-input placeholder="" formControlName="entityId" placeholder="Entity ID" 
                            [ngModel]="templateSelected?.entityId" />
                    </nz-form-control>
                </nz-form-item>
                

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="4" nzRequired>Operator Template Id</nz-form-label>
                    <nz-form-control [nzSm]="12" nzOffset="1">
                        <input nz-input placeholder="Operator Template Id" formControlName="dltTemplateId"
                            [ngModel]="templateSelected?.operatorTemplateId" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control style="margin-left: 45%;">
                        <button nz-button nzType="primary"  (click)="submitForm()">Submit</button>
                        <button nz-button (click)="resetForm()">Clear</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>

    <!-- </div>
</div> -->
