import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
// import { CreateUrlComponent } from './create-url/create-url.component';
// import { ViewShortComponent } from './view-short/view-short.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';



@Component({
  selector: 'app-create-url',
  templateUrl: './create-url.component.html',
  styleUrls: ['./create-url.component.scss']
})
export class CreateUrlComponent implements OnInit {

  viewshortUrl = this.fb.group({
    callbackUrl: ["http://"],
    callbackUrlValue:"",
    domainName: ["", Validators.required],
    isActive: "Y",
    isDynamic: "N",
    shortCode: '',
    loggedInUserName: this.storageService.username,
    longUrl: "http://",
    longUrlValue: ["", Validators.required],
    name: ["", Validators.required],
  });



  listOfData: any;
  listActiveHost: any;
  shortCode: string = '';


  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private reportService: ReportService,
    private utilService: UtilsService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getActiveDomains();
    this.getAllShortUrl();
  }

  submitForm() {

    if (!this.viewshortUrl.valid) {
      Object.values(this.viewshortUrl.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    let formData = this.viewshortUrl.getRawValue();

    if (formData.callbackUrlValue !== '') {

      formData.callbackUrl = formData.callbackUrl + this.viewshortUrl.get('callbackUrlValue')?.value;
    }else{

      formData.callbackUrl=""
    }

    if (formData.longUrlValue !== "") {
      formData.longUrl = formData.longUrl + formData.longUrlValue;
    }

    delete formData.callbackUrlValue;
    delete formData.longUrlValue;
    delete formData.shortCode;

    this.reportService.addviewshortUrl(formData).subscribe(data => {
      this.getAllShortUrl();
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
        this.getAllShortUrl();
        // this.location.reload();
      } else {
        this.utilService.showErrorMsg(data.message);
        this.shortCode = data.data.shortCode;
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
   this.resetForm();
    setTimeout(function(){
    window.location.reload();
    }, 2000);
  }

  resetForm() {
    this.viewshortUrl.reset();
    this.viewshortUrl.patchValue({
      callbackUrl: "http://",
      isActive: "Y",
      isDynamic: "N",
      loggedInUserName: this.storageService.username,
      longUrl: "http://",
    });
  }

  deleteShortUrl(data: any) {
    let params = { "loggedInUserName": "airteltesting", "shortUrlId": data.shortUrlId, "operation": "removeShortUrlFromList" };
    this.reportService.deleteShortUrl(params).subscribe(data => {
      this.getAllShortUrl();
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    })
  }

  getAllShortUrl() {
    this.reportService.listAllShortUrl().subscribe(data => {
      this.listOfData = data.data.shortUrlList;
    })
  }
  getActiveDomains() {
    this.reportService.getActiveDomain().subscribe((data: any) => {
      this.listActiveHost = data?.data.hostNameList;
    })
  }
}
