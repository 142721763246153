import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-senderid-report',
  templateUrl: './senderid-report.component.html',
  styleUrls: ['./senderid-report.component.scss']
})
export class SenderidReportComponent implements OnInit {
  listData:[]=[];
  total:any[]=[]
  listOfColumn = [
    {
      title: 'Summary Date',
      compare: (a: any, b: any) => a.summaryDate.localeCompare(b.summaryDate),
      priority: false
    },
    {
      title: 'Sender ID',
      compare: (a: any, b: any) => a.senderId.localeCompare(b.senderId),
      priority: false
    },
    {
      title: 'Total Request',
      compare: (a: any, b: any) => a.totalRequest-b.totalRequest,
      priority: false
    },
    {
      title: 'Total Rejected',
      compare: (a: any, b: any) => a.totalRejected-b.totalRejected,
      priority: false
    },
    {
      title: 'Total Submit',
      compare: (a: any, b: any) => a.totalSubmit-b.totalSubmit,
      priority: false
    },
    {
      title: 'Total Delivered',
      compare: (a: any, b: any) => a.totalDelivered-b.totalDelivered,
      priority: false
    }, {
      title: 'Total Failed',
      compare: (a: any, b: any) => a.totalFailed-b.totalFailed,
      priority: false
    }, {
      title: 'Total Awaited',
      compare: (a: any, b: any) => a.totalAwaited-b.totalAwaited,
      priority: false
    }
  ];

  listOfData: any;
  downloadLink: any;
  validateForm!: FormGroup;
  pageNumber: number = 1;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  ngOnInit(): void {

    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],

    });


    // this.getSenderIdReport();
  }

  submitForm(): void {

    let form = this.validateForm.value;
    if (form.rangePicker) {
      this.getSenderIdReport(form.rangePicker[0], form.rangePicker[1])
    }
  }
  totalreqsum!: number;
  totalreg!: number;
  totaldel!: number;
  totalfal!: number;
  totalsub!: number;
  totalawait!: number;

  getSenderIdReport(from: any = this.today, to: any = this.today) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to)
    }

    this.reportService.getSenderIDReport(params).subscribe(data => {
      this.listOfData = data.data.grid;
      this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
      this.totalreqsum=0;
      this.totalreg=0;
      this.totaldel=0;
      this.totalfal=0;
      this.totalsub=0;
      this.totalawait=0;
for(let i=0;i<this.listOfData.length;i++){

let val=+this.listOfData[i].totalRequest
this.totalreqsum=this.totalreqsum+val

let valreg=+this.listOfData[i].totalRejected
this.totalreg=this.totalreg+valreg

let valsub=+this.listOfData[i].totalSubmit
this.totalsub=this.totalsub+valsub

let valdel=+this.listOfData[i].totalDelivered
this.totaldel=this.totaldel+valdel

let valfail=+this.listOfData[i].totalFailed
this.totalfal=this.totalfal+valfail

let valaw=+this.listOfData[i].totalAwaited
this.totalawait=this.totalawait+valaw
}
this.total.pop()
this.total.push(
  {
    "summaryDate": "Total ",
    "senderId":"   ",
    "totalRequest": (this.totalreqsum !=0)?this.totalreqsum:'0',
    "totalRejected":(this.totalreg !=0)?this.totalreg:'0',
    "totalSubmit": (this.totalsub !=0)?this.totalsub:'0',
    "totalDelivered":(this.totaldel !=0)?this.totaldel:'0',
    "totalFailed": (this.totalfal !=0)?this.totalfal:'0',
    "totalAwaited": (this.totalawait !=0)?this.totalawait:'0'
})





    })
  }

  downloadFile() {
    this.reportService.downloadFile(this.downloadLink);
  }
  nextPage() {
    this.pageNumber++;
    this.getSenderIdReport();
  }

  previousPage() {
    this.pageNumber--;
    this.getSenderIdReport();
  }
  ngOnDestroy() {
  }
}
