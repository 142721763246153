import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { StorageService } from 'src/app/services/storage/storage.service';
// import { UserService } from 'src/app/sevices/user.service';
// import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/sevices/user.service';
import { ReportService } from 'src/app/services/reports/report.service';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],

})

export class EditModalComponent implements OnInit {
  entityId: any;
  value = '';
  submitted: boolean= false;
  data: any;
  modalData: any;
  loggedInUser = '';
  editData: any;
  @Input() name: string;

  @Input() email: string;
  @Output() savedChanges: EventEmitter<any> = new EventEmitter<any>();

  editTemplateForm: FormGroup = this.fb.group({
    entityId: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    templateId: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    senderId: ['null', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    templateText: ['', [Validators.required]],
  });



  saveChanges(): void {

    this.savedChanges.emit({ name: this.name, email: this.email });
  }

  constructor(
    private fb: FormBuilder,
    private templateService: TemplateService,
    private utils: UtilsService,
    private storageService: StorageService,
    private _userService: UserService,
    private modalRef: NzModalRef,
    private httpClient: HttpClient,
    private reportService: ReportService,
  ) {

    this.name = '';
  this.email = '';
  }


  isSaving = false;

  editFormClick(): void {
    if (this.editTemplateForm.invalid) {
      this.submitted = true;
      return;
    }

    this.isSaving = true; // Set the saving state

    const dt = {
      contentTemplateType: this.data?.contentTemplateType,
      entityId: this.editTemplateForm.get('entityId')?.value,
      loggedInUserName: this.storageService.username,
      operation: 'editContentTemplate',
      operatorTemplateId: this.editTemplateForm.get('templateId')?.value,
      senderId: this.editTemplateForm.get('senderId')?.value,
      status: this.data?.status,
      templateDescription: this.data?.templateDescription,
      templateText: this.editTemplateForm.get('templateText')?.value,
      templateTitle: this.data?.templateTitle,
      templateType: this.data?.templateType,
      userId: this.storageService.userId,
      variableCount: this.data?.variableCount,
      id: this.data?.templateId,
    };

    console.log(dt);

    this.templateService.saveTemplate(dt).subscribe(
      data => {
        this.utils.showSuccessMsg(data.message);
        this.isSaving = false;
        // this.refreshContentTemplates();
        this.modalRef.destroy();
      },
      err => {
        this.utils.showErrorMsg("Something Went Wrong");
        this.isSaving = false;
      }
    );
    // this.refreshContentTemplates();
     setTimeout(function(){
      window.location.reload();
      }, 1500);
  }
  // public refreshContentTemplates(): void {
  //   const params = {/* Define your parameters for the API call */};

  //   this.reportService.getAllContentTemplates(params).subscribe(
  //     refreshedData => {

  //     },
  //     error => {

  //     }
  //   );
  // }

  onSenderIdChange(){

  }

  cancel(): void {
    this.modalRef.destroy();
  }
  onEntityIdChange(): void {
    const entityIdControl = this.editTemplateForm.get('entityId');

    if (entityIdControl) { // Check if the control exists
      if (entityIdControl.value && isNaN(entityIdControl.value)) {
        entityIdControl.setErrors({ nonNumeric: true });
      } else {
        entityIdControl.setErrors(null);
      }
    }
  }

  onEntityIdChangee(): void {
    const templateIdControl = this.editTemplateForm.get('templateId');

    if (templateIdControl) { // Check if the control exists
      if (templateIdControl.value && isNaN(templateIdControl.value)) {
        templateIdControl.setErrors({ nonNumeric: true });
      } else {
        templateIdControl.setErrors(null);
      }
    }
  }



  ngOnInit(): void {
    this.editTemplateForm = this.fb.group({
      entityId: [this.data?.entityId],
      templateId: [this.data?.operatorTemplateId],
      senderId: [this.data?.senderId],
      templateText: [this.data?.templateText],
    });

    this.editData = this.data;
    this.loggedInUser = ''; // Update this line with actual user data
  }



}
