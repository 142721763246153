<nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
  <ul style="border-top: 1px solid black;"  [nzMode]="'inline'" nz-menu nzTheme="dark" nzTheme="light" class="left-navigation" >
    <!-- <ul nz-menu nzTheme="dark" nzMode="horizontal" class="header-menu"> -->
  <!-- <ul nz-menu nzTheme="dark" nzTheme="light" class="left-navigation" [nzMode]="'inline'" [nzInlineCollapsed]='sidebarshow'> -->
    
    <li nz-menu-item nzMatchRouter id="dashboard" >
  
      <a routerLink="/dashboard">
    
        <i nz-icon nzType="fund-projection-screen"></i> <!-- Add the icon here -->
    
        <span style="margin-top: 50%; ">Dashboard</span>
    
      </a>
    
    </li>
    <!-- <li nz-submenu>
      <span title>
        <span nzType="deployment-unit" class="nav-icon"><small>DLT Management</small></span>
      </span>
      <ul>
        <li nz-menu-group  >
          <ul>
            <li nz-menu-item routerLink="/senderId-mgmt">Sender Id / Header ID</li>
            <li nz-menu-item routerLink="/content-template-mgmt">Content Template ID</li>
          </ul>
        </li>
      </ul>
    </li> -->
    
    <li nz-submenu nzclose nzTitle="DLT Management" nzIcon="pic-right">
      <ul>
      <li nz-menu-item nzMatchRouter style="padding-left: 14%; ">
          <a routerLink="/senderId-mgmt">
              <i nz-icon nzType="right-square" nzTheme="outline"></i>
              <span><small>Sender Id / Header ID</small></span>
          </a>
          </li>
          <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
              <a routerLink="/content-template-mgmt">
              <i nz-icon nzType="right-square" nzTheme="outline"></i>
              <span><small>Content Template ID</small></span>
            </a>
              </li>
      </ul>
      </li>
    <!-- <li nz-submenu  *ngIf="userType=='web'">
      <span title>
        <span   class="nav-icon"><small>Short Link Management</small></span>
      </span>
      <ul>
        <li nz-menu-group >
          <ul>
            <li nz-menu-item routerLink="/short-url">New Short Link</li>
            <li nz-menu-item routerLink="/domain-manager">Domain Manager</li>
          </ul>
        </li>
      </ul>
    </li> -->
    
    <li nz-submenu nzclose nzTitle="Short Link Mgmt" nzIcon="fund" *ngIf="userType=='web'"  >
      <ul class="wee">
      <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
          <a routerLink="/short-url">
              <i nz-icon nzType="rise" nzTheme="outline"></i>
             <span><small> New Short Link</small></span>
          </a>
          </li>
          <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
              <a routerLink="/domain-manager">
              <i nz-icon nzType="rise" nzTheme="outline"></i>
             <span><small> Domain Manager</small></span>
            </a>
              </li>
      </ul>
      </li>
      <li nz-submenu nzclose nzTitle="Campaign Mgmt" nzIcon="message" *ngIf="userType=='web'">
        <ul class="wee">
        <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
            <a routerLink="/quick-campaign">
                <i nz-icon nzType="red-envelope" nzTheme="outline"></i>
                <span><small>Quick Campaign</small></span>
            </a>
            </li>
            <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
                <a routerLink="/upload-campaign">
                <i nz-icon nzType="red-envelope" nzTheme="outline"></i>
                 <span><small>Upload Campaign</small></span>
              </a>
                </li>
                <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
                  <a routerLink="/dynamic-campaign">
                  <i nz-icon nzType="red-envelope" nzTheme="outline"></i>
                   <span><small>Dynamic Campaign</small></span>
                </a>
                  </li>
                  <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
                    <a routerLink="/group-campaign">
                    <i nz-icon nzType="red-envelope" nzTheme="outline"></i>
                 <span><small>Group Campaign</small></span>
                  </a>
                    </li>
                    <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
                      <a routerLink="/scheduled-campaign">
                      <i nz-icon nzType="red-envelope" nzTheme="outline"></i>
                       <span><small>Scheduled Campaign</small></span>
                    </a>
                      </li>
        </ul>
        </li>
    
    <!-- <li nz-submenu *ngIf="userType=='web'">
      <span title>
        <span class="nav-icon"><small>Campaign Management</small></span>
      </span>
      <ul>
        <li nz-menu-group >
          <ul>
            <li nz-menu-item routerLink="/quick-campaign">Quick Campaign</li>
            <li nz-menu-item routerLink="/upload-campaign">Upload Campaign</li>
            <li nz-menu-item routerLink="/dynamic-campaign">Dynamic Campaign</li>
            <li nz-menu-item routerLink="/group-campaign">Group Campaign</li>
            <li nz-menu-item routerLink="/scheduled-campaign">Scheduled Campaign</li>
          </ul>
        </li>
      </ul>
    </li> -->
    
    <li nz-submenu nzclose nzTitle="Reports " nzIcon="appstore" >
      <ul >
      <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
          <a routerLink="/summary-report">
              <i nz-icon nzType="delivered-procedure" nzTheme="outline"></i>
               <span><small>Summary Report</small></span>
          </a>
          </li>
          <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
              <a routerLink="/senderId-report">
              <i nz-icon nzType="delivered-procedure" nzTheme="outline"></i>
               <span><small>Sender ID Wise Report</small></span>
            </a>
              </li>
              <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
                <a routerLink="/detailed-report">
                <i nz-icon nzType="delivered-procedure" nzTheme="outline"></i>
    
                <span><small>Detailed Report</small></span></a>
                </li>
                <li nz-menu-item nzMatchRouter style="padding-left: 14%;">
                  <a routerLink="/download-report">
                  <i nz-icon nzType="delivered-procedure" nzTheme="outline"></i>
                 <span><small> Consolidated Report </small></span>
                </a>
                  </li>
                <li nz-menu-item nzMatchRouter style="padding-left: 14%;" *ngIf="userType=='web'">
                  <a routerLink="/campaignid-report" >
                  <i nz-icon nzType="delivered-procedure" nzTheme="outline"></i>
                  <span><small> Campaign Report</small></span>
                </a>
                  </li>
                  <li nz-menu-item nzMatchRouter style="padding-left: 14%;" *ngIf="userType=='web'">
                    <a routerLink="/clicker-report" >
                    <i nz-icon nzType="delivered-procedure" nzTheme="outline"></i>
                     <span><small>Clicker-Report</small></span>
                  </a>
                    </li>
      </ul>
      </li>
    
    <!-- <li nz-submenu>
      <span title>
        <span  class="nav-icon"><small>Report Management</small></span>
      </span>
      <ul>
        <li nz-menu-group >
          <ul>
            <li nz-menu-item routerLink="/summary-report">Summary Report</li>
            <li nz-menu-item routerLink="/senderId-report">Sender ID Wise Report</li>
            <li nz-menu-item routerLink="/detailed-report">Detailed Report</li>
            <li nz-menu-item routerLink="/campaignid-report"  *ngIf="userType=='web'">Campaign ID wise Report</li>
            <li nz-menu-item routerLink="/clicker-report"  *ngIf="userType=='web'">Clicker-Report</li>
             <li nz-menu-item routerLink="/clicker">Clicker-Report</li>
            <li nz-menu-item routerLink="/download-report">Consolidated Report</li>
          </ul>
      </ul>
    </li> -->
    <li nz-menu-item nzMatchRouter id="phonebook">
      <a routerLink="/phonebook">
        <i nz-icon [nzType]="'phone'"></i> <!-- Add the icon here -->
        <span>Phonebook</span>
      </a>
    </li>
    <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Phonebook' : ''"
      routerLink="/phonebook">
      <span nz-icon nzType="usergroup-add" class="nav-icon"></span>
    </li> -->
    <li nz-menu-item nzMatchRouter id="documentation" *ngIf="userType !=='web'">
      <a routerLink="/documentation" >
        <i nz-icon nzType="file"></i> <!-- Add the icon here -->
        <span>Documentation</span>
      </a>
    </li>
    
    <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Documentation' : ''"
    routerLink="/documentation" *ngIf="userType !=='web'">
    <span nz-icon nzType="file" nzTheme="outline" class="nav-icon"></span>
    </li> -->
    <!-- <li nz-menu-item nzMatchRouter id="credit-history" *ngIf="showCreditHistory" >
      <a routerLink="/credit-history" >
        <i nz-icon [nzType]="'dollar-circle'"></i> 
        <span>Credit-History</span>
      </a>
    </li> -->
    <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Credit History' : ''"
      routerLink="/credit-history" 
>
      <span nz-icon nzType="dollar-circle" class="nav-icon"></span>
    </li> -->
    
    </ul>
  </nz-sider>