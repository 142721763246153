import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-domain-manager',
  templateUrl: './domain-manager.component.html',
  styleUrls: ['./domain-manager.component.scss']
})
export class DomainManagerComponent implements OnInit {


  listOfColumn = [
    {
      title: 'Title',
      compare: (a: any, b: any) => a.title.localeCompare(b.title),
      priority: 1
    },
    {
      title: 'Domain Name',
      compare: (a: any, b: any) => a.domainName.localeCompare(b.domainName),
      priority: 2
    },
    {
      title: 'Created Date',
      compare: (a: any, b: any) => a.createdDate - b.createdDate,
      priority: 3
    },
    {
      title: 'Is Approved',
      compare: (a: any, b: any) => a.isApproved.localeCompare(b.isApproved),
      priority: 4
    },
  ];

  listOfData: any;
  submitted = false;

  addDomain = this.fb.group({
    title: ['', [Validators.required]],
    domainName: [''],
    loggedInUserName: this.storageService.username
  });

  constructor(
    private reportService: ReportService,
    private storageService: StorageService,
    private utilService: UtilsService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.listDomainManager();
  }

  submitForm() {
    if (!this.addDomain.valid) {
      return;
    }
    this.reportService.addNewDomain(this.addDomain.getRawValue()).subscribe(data => {
      this.listDomainManager();
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
    this.resetForm();
    // window.location.reload()
  }

  resetForm() {
    this.addDomain.reset();
    this.addDomain.patchValue({
      loggedInUserName: this.storageService.username
    })
  }

  deleteDomain(data: any) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "domainName": data.domainName,
      "id": data.id,
      "operation": "removeHostNameFromList"
    }

    this.reportService.deleteDomain(params).subscribe(data => {
      this.listDomainManager();
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
  }

  listDomainManager() {
    this.reportService.listDomainManager().subscribe(data => {
      this.listOfData = data.data.hostNameList;
    });
  }
}
