import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private router: Router,
    private storageService: StorageService) { }

  public loggedInUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isTokenAvailable()) {
      this.loggedInUser.next(true)
      return true;
    } else {
      this.router.navigateByUrl('/');
      this.loggedInUser.next(false)
      return false;
    }
  }

  public isTokenAvailable(): boolean {
    return this.getSession() ? true : false;
  }

  getSession(): string {
    let token = this.storageService.accessToken;
    return token;
  }

}
