<div class="form-input">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <div nz-row [nzGutter]="24">
          <div nz-col>
              <nz-form-item>
                  <nz-form-label nzRequired>Select Range</nz-form-label>
                  <nz-form-control>
                      <!-- [nzDefaultPickerValue]="today"  -->
                      <nz-range-picker  formControlName="rangePicker" [nzDisabledDate]="disabledDate"
                          [nzRanges]="ranges" nzFormat="yyyy-MM-dd">
                      </nz-range-picker>
                  </nz-form-control>
              </nz-form-item>
              
          </div>
          <div nz-col>
              <nz-form-item>
                  <nz-form-control>
                      <button nz-button nzType="primary">Submit</button>
                  </nz-form-control>
              </nz-form-item>
          </div>
      </div>
  </form>
</div>

<app-simple-table
[listOfData]="listOfData"
[listOfColumn]="listOfColumn"
[pagination]="true"
[type]="'scheduled-campaign'"
(deleteRowEvent)="deleteRow($event)"
></app-simple-table>