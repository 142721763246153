import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  @Input() type: any;
  @Input() title: any;
  @Input() chartOptions: any;

  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }
  // options = ['Daily', 'Monthly'];

  constructor() { }

  ngOnInit(): void {
    this.checkScreenSize()
  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }
}
