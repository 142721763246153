<div class="form-input">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <div nz-row [nzGutter]="24">
            <div nz-col>
                <nz-form-item>
                    <nz-form-label nzRequired>Select Range</nz-form-label>
                    <nz-form-control>
                        <nz-range-picker formControlName="rangePicker" [nzDisabledDate]="disabledDate"
                            [nzRanges]="ranges" nzFormat="yyyy-MM-dd">
                        </nz-range-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button nzType="primary">Submit</button>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col *ngIf="downloadLink">
                <button nz-button nzType="primary" (click)="downloadFile()">
                    <span nz-icon nzType="download"></span>
                    Download
                </button>
            </div>
        </div>
    </form>
</div>

<app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn" [type]="'campaignid-report'"
    [pagination]="true" (rowClick)="receiveRowData($event)"></app-simple-table>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Analytics Report" (nzOnCancel)="handleCancel()" (nzOnOk)=" handleOk()" [nzWidth]="800">
    <ng-container *nzModalContent>

        <nz-tag [nzColor]="'magenta'">Total Submit : {{chartData.totalSubmit}}</nz-tag>
        <nz-tag [nzColor]="'red'">Total Delivered : {{chartData.totalDelivered}}</nz-tag>
        <nz-tag [nzColor]="'volcano'">Total Failed : {{chartData.totalFailed}}</nz-tag>
        <nz-tag [nzColor]="'orange'">Total Awaited : {{chartData.totalAwaited}}</nz-tag>
        <nz-tag [nzColor]="'gold'">Total Clicks : {{chartData.totalClicks}}</nz-tag>
        <br /><br />
        <div nz-row>
            <div nz-col [nzSpan]="12">
                <h4 nz-typography>Clicker Chart</h4>
                <app-charts type="pie" title="Clicker Chart" [chartOptions]="clickerChart"></app-charts>
            </div>
            <div nz-col [nzSpan]="12">
                <h4 nz-typography>Delivery Chart</h4>
                <app-charts type="pie" title="Delivery Chart" [chartOptions]="deliveryChart"></app-charts>
            </div>
        </div>

    </ng-container>
</nz-modal>