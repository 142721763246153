import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { endOfMonth, addWeeks, sub } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import {NUMBER_REGEX, PLAIN_TXT_REGEX } from 'src/environments/environment';
import { isNumber } from 'util';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  today = new Date();
  constructor(
    private message: NzMessageService
  ) { }

  isPlainMsg(messageText: string): boolean {
    let plain = new RegExp(PLAIN_TXT_REGEX);
    return plain.test(messageText.trim());
  }

  isNumber(value: any) {
    const reg = NUMBER_REGEX;
    return ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-');
  }

  showErrorMsg(msg: string) {
    this.message.create('error', msg);
  }

  showSuccessMsg(msg: string) {
    this.message.create('success', msg);
  }

  getToday() {
    return this.today;
  }

  getEndOfTheMonth() {
    return endOfMonth(this.today);
  }

  getLastWeekDate() {
    return sub(this.today, { weeks: 1 });
  }

  getLastMonthDate() {
    return sub(this.today, { months: 1 });
  }

  getCurrentTimestamp() {
    return this.today.getTime()
  }

  formatDate(date: any, format: string = 'yyyy-MM-dd') {
    return new DatePipe('en-US').transform(date, format);
  }
  dateFormat(date: any, format: string = 'dd-MM-yyyy') {
    return new DatePipe('en-US').transform(date, format);
  }

  disabledDate(date: any, format: string = 'yyyy-MM-dd') {
    // Can not select days before today and today
    return date && date.valueOf() < Date.now();
  }

  /**
* Returns a date set to the begining of the month
*
* @param {Date} myDate
* @returns {Date}
*/
  beginningOfMonth(myDate = this.today) {
    let date = new Date(myDate);
    date.setDate(1)
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  /**
   * Returns a date set to the end of the month
   *
   * @param {Date} myDate
   * @returns {Date}
   */
  endOfMonth(myDate = this.today) {
    let date = new Date(myDate);
    date.setDate(1); // Avoids edge cases on the 31st day of some months
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  differenceInCalendarDays(fromData: any, toDate: any) {
    const difference = fromData - toDate;
    // the difference is in miliseconds.
    // divide it by miliseconds in a day to get the difference in days
    const differenceInDays = Math.round(Math.abs((difference) / (24 * 60 * 60 * 1000)))
    return differenceInDays;
  }


  formatNumber(value: string): string {
    const stringValue = `${value}`;
    const list = stringValue.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  }

}
