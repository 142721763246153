import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { PhonebookService } from 'src/app/services/phonebook/phonebook.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  listOfGroups: any;
  fileList: any[] = [];

  addContactsForm = this.fb.group({
    groupName: ['', Validators.required,],
    contactNumber: ['', [Validators.required, Validators.pattern("^(|0)?[0-9]{10}$")]],
    contactName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]],
    operation: ['addContactNumber'],
  })

  uploadBlacklist = this.fb.group({
    groupName: ['', Validators.required],
    fileName: ['',]
  });

  userName = { loggedInUsername: this.storageService.username }

  constructor(
    private phonebookService: PhonebookService,
    private storageService: StorageService,
    private utils: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getPhoneBookGroupData();
  }
  resetAddBlacklistForm() {
    this.fileList=[];
    this.uploadBlacklist.patchValue({
      groupName: [''],
    fileName: ['',]
    });
    this.addContactsForm.patchValue({
      groupName: [''],
      contactNumber: [''],
      contactName: [''],
    });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  uploadBulk() {
    if (this.uploadBlacklist.invalid) {
      this.uploadBlacklist.markAllAsTouched(); // Mark all controls as touched to display validation errors
      return;
    }

    let files = this.fileList[0];
    if (!files) {
      this.utils.showErrorMsg('Please select a file to proceed.');
      return;
    }

    let formData: FormData = new FormData();
    formData.append('fileName', files.name);
    formData.append('file', files);
    formData.append("userName", this.storageService.username);
    formData.append("fileType", files.type);
    formData.append("groupId", this.uploadBlacklist.get('groupName')?.value);

    this.phonebookService.uploadContact(formData).subscribe(data => {
      if (data.result === 'Success') {
        this.utils.showSuccessMsg(data.message);
      } else {
        this.utils.showErrorMsg(data.message);
      }
    });

    this.uploadBlacklist.reset();
     setTimeout(function(){
      window.location.reload();
      }, 2000);
  }



  //   Object.values(this.addTemplateForm.controls).forEach(control => {
  //     if (control.invalid) {
  //       control.markAsDirty();
  //       control.updateValueAndValidity({ onlySelf: true });
  //     }
  //   });
  //   return;
  // }

  uploadreset() {
    this.fileList=[];
    this.uploadBlacklist.reset(); // Corrected method call
  }


  submitAddContactForm() {
    if (!this.addContactsForm.valid) {
      this.utils.showErrorMsg("Please Fill Required");
      return
    }

      const formData = { ...this.userName, ...this.addContactsForm.getRawValue() };
      formData.contactNumber = "91" + formData.contactNumber;

      this.phonebookService.addContact(formData).subscribe(response => {
        if (response.result === 'Success') {
          this.utils.showSuccessMsg(response.message);
          this.resetAddContactForm();
          // Refresh the phone book group data after adding the contact
          this.getPhoneBookGroupData();
        } else {
          this.utils.showErrorMsg(response.message);
        }
      });
       setTimeout(function(){
        window.location.reload();
        }, 2000);
  }

  resetAddContactForm() {
    this.addContactsForm.reset();
    this.addContactsForm.patchValue({
      operation: 'addContactNumber'
    });
  }



  getPhoneBookGroupData() {
    this.phonebookService.getAllPhoneBookGroup().subscribe((response: any) => {
      this.listOfGroups = response.data.groupList;
    });
  }
}

