import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators ,FormGroup} from '@angular/forms';
import { PhonebookService } from 'src/app/services/phonebook/phonebook.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent {

selected: boolean=false;

  listOfColumn = [
    {
      title: 'Group Name',
      compare: (a: any, b: any) => a.groupName.localeCompare(b.groupName),
      priority: 1
    },

    {
      title: 'Total Numbers',
      compare: (a: any, b: any) => a.numbersInGroupCount.localeCompare(b.numbersInGroupCount),
      priority: 3
    },
    {
      title: 'Description',
      compare: (a: any, b: any) => a.groupDescription.localeCompare(b.groupDescription),
      priority: 2
    },
    // {
    //   title: 'Action',
    //   compare: (a: any, b: any) => a.groupId.localeCompare(b.groupId),
    //   priority: 4
    // }
  ];

  listOfData: any;

  addGroupForm = this.fb.group({
    groupName: ['',[Validators.required,Validators.pattern(/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i)]],
    groupDescription:  [''],
  })
  userName = { loggedInUserName: this.storageService.username }

  constructor(
    private phonebookService: PhonebookService,
    private storageService: StorageService,
    private utils: UtilsService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getPhoneBookGroupData();
  }

  resetAddBlacklistForm() {
    this.addGroupForm.reset()
  }

  submitForm() {
    if(this.addGroupForm.invalid)
    { this.selected=true; return ;
    }
    const data = { ...this.userName, ...this.addGroupForm.getRawValue() }
    this.phonebookService.createGroup(data).subscribe(data => {
      this.getPhoneBookGroupData();

      if (data.result === 'Success') {
        this.addGroupForm.reset();
        this.utils.showSuccessMsg(data.message)
        this.getPhoneBookGroupData();
      } else {
        this.utils.showSuccessMsg(data.message)
      }

    })
     setTimeout(function(){
     window.location.reload();
     }, 2000);
  }

  resetForm() {
    this.addGroupForm.reset();
  }

  deleteGroup(data: any) {
    const deleteData = {
      groupDescription: data.groupDescription,
      groupName: data.groupName,
      loggedInUserName: this.storageService.username,
      operation: "removeGroupFromList",
    }
    this.phonebookService.deleteGroup(deleteData).subscribe(((response: any) => {
      console.log(response)
      this.getPhoneBookGroupData();

      if (data.result === 'Success') {
        this.utils.showSuccessMsg(response.message)
      } else {
        this.utils.showSuccessMsg(response.message)
      }
    })
    );
  }

  getPhoneBookGroupData() {
    this.phonebookService.getAllPhoneBookGroup().subscribe((response: any) => {
      this.listOfData = response?.data?.groupList;
    });
  }
}
