import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { AddViewSenderidComponent } from './pages/add-view-senderid/add-view-senderid.component';
import { DynamicCampaignComponent } from './pages/campaign-mgmt/dynamic-campaign/dynamic-campaign.component';
import { GroupCampaignComponent } from './pages/campaign-mgmt/group-campaign/group-campaign.component';
import { QuickCampaignComponent } from './pages/campaign-mgmt/quick-campaign/quick-campaign.component';
import { ScheduledCampaignComponent } from './pages/campaign-mgmt/scheduled-campaign/scheduled-campaign.component';
import { UploadCampaignComponent } from './pages/campaign-mgmt/upload-campaign/upload-campaign.component';
import { ContentTemplateMgmtComponent } from './pages/content-template-mgmt/content-template-mgmt.component';
import { CreditHistoryComponent } from './pages/credit-history/credit-history.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DomainManagerComponent } from './pages/domain-manager/domain-manager.component';
import { PhonebookComponent } from './pages/phonebook/phonebook.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CampaignidReportComponent } from './pages/reports/campaignid-report/campaignid-report.component';
import { ClickerReportComponent } from './pages/reports/clicker-report/clicker-report.component';
import { DetailedReportComponent } from './pages/reports/detailed-report/detailed-report.component';
import { DownloadReportComponent } from './pages/reports/download-report/download-report.component';
import { SenderidReportComponent } from './pages/reports/senderid-report/senderid-report.component';
import { SummaryReportComponent } from './pages/reports/summary-report/summary-report.component';
import { ShortUrlComponent } from './pages/short-url/short-url.component';
import { ViewShortComponent } from './pages/short-url/view-short/view-short.component';
import { SigninComponent } from './pages/signin/signin.component';
import { AuthGuardGuard } from './services/auth-guard/auth-guard.guard';

import { BlacklistComponent } from './pages/phonebook/blacklist/blacklist.component';

import { ClickerComponent } from './pages/reports/clicker/clicker.component';



const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'dashboard', component: DashboardComponent, data: { pageName: 'Dashboard' }, canActivate: [AuthGuardGuard] },
  { path: 'summary-report', component: SummaryReportComponent, data: { pageName: 'Summary report' }, canActivate: [AuthGuardGuard] },
  { path: 'senderId-report', component: SenderidReportComponent, data: { pageName: 'Sender ID report' }, canActivate: [AuthGuardGuard] },
  { path: 'detailed-report', component: DetailedReportComponent, data: { pageName: 'Detailed report' }, canActivate: [AuthGuardGuard] },
  { path: 'download-report', component: DownloadReportComponent, data: { pageName: 'Download report' }, canActivate: [AuthGuardGuard] },
  { path: 'credit-history', component: CreditHistoryComponent, data: { pageName: 'Credit History' }, canActivate: [AuthGuardGuard] },
  { path: 'senderId-mgmt', component: AddViewSenderidComponent, data: { pageName: 'Add/View Sender ID' }, canActivate: [AuthGuardGuard] },
  { path: 'content-template-mgmt', component: ContentTemplateMgmtComponent, data: { pageName: 'Content Template Management' }, canActivate: [AuthGuardGuard] },
  { path: 'phonebook', component: PhonebookComponent, data: { pageName: 'Phonebook' }, canActivate: [AuthGuardGuard] },
  { path: 'campaignid-report', component: CampaignidReportComponent, data: { pageName: 'Campaign report' }, canActivate: [AuthGuardGuard] },
  { path: 'clicker-report', component: ClickerReportComponent, data: { pageName: 'Clicker Detailed report' }, canActivate: [AuthGuardGuard] },
  { path: 'short-url', component: ShortUrlComponent, data: { pageName: 'New Short Url' }, canActivate: [AuthGuardGuard] },
  { path: 'ViewShortComponent', component: ViewShortComponent, data: { pageName: 'view-short-URL' }, canActivate: [AuthGuardGuard] },
  { path: 'short-url', component: ShortUrlComponent, data: { pageName: 'New Short Url' }, canActivate: [AuthGuardGuard] },
  { path: 'domain-manager', component: DomainManagerComponent, data: { pageName: 'Domain Manager' }, canActivate: [AuthGuardGuard] },
  { path: 'scheduled-campaign', component: ScheduledCampaignComponent, data: { pageName: 'Scheduled Campaign' }, canActivate: [AuthGuardGuard] },
  { path: 'quick-campaign', component: QuickCampaignComponent, data: { pageName: 'Quick Campaign' }, canActivate: [AuthGuardGuard] },
  { path: 'upload-campaign', component: UploadCampaignComponent, data: { pageName: 'Upload Campaign' }, canActivate: [AuthGuardGuard] },
  { path: 'dynamic-campaign', component: DynamicCampaignComponent, data: { pageName: 'Dynamic Campaign' }, canActivate: [AuthGuardGuard] },
  { path: 'group-campaign', component: GroupCampaignComponent, data: { pageName: 'Group Campaign' }, canActivate: [AuthGuardGuard] },
  { path: 'profile', component:ProfileComponent},

  {path: 'documentation', component:DocumentationComponent},
  {path: 'blacklist', component:BlacklistComponent},


  {path: 'clicker', component:ClickerComponent, data: { pageName: 'Clicker report' }, canActivate: [AuthGuardGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
