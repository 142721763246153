  import { Component, OnInit } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { Router } from '@angular/router';
  import { AuthService } from 'src/app/services/auth/auth.service';
  import { StorageService } from 'src/app/services/storage/storage.service';
  import { UtilsService } from 'src/app/services/utils/utils.service';
  import { AuthGuardGuard } from 'src/app/services/auth-guard/auth-guard.guard';

  @Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
  })
  export class SigninComponent implements OnInit {
    showOtpBox: boolean = false;
    submitted: boolean = false;
    loginbox: boolean = true;


    inputValue: string = '';
    inputValue1: string = '';
    outputValue: string = '';
    extraTemplate: any;
    userType:string='';


    onInputChange() {
      this.outputValue = this.inputValue;
    }

    onInputChange1() {
      this.outputValue = this.inputValue1;
    }

    validateForm!: FormGroup;
    otpform!: FormGroup;
    constructor(
      private fb: FormBuilder,
      private AuthGuardGuard: AuthGuardGuard,
      private AuthService: AuthService,
      private storageService: StorageService,
      private router: Router,
      private utils:UtilsService
    ) { 

     
}

    


    submitForm(): void {
      if (this.validateForm.valid) {
        if(this.inputValue.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)){
        this.userType = 'email'
        }else{
          this.userType = 'username'
        }
        this.AuthService.signIn({ ...this.validateForm.value, type: this.userType }).subscribe(data => {
          if (data.result === 'success') {
            localStorage.setItem('role', data.userType);
            sessionStorage.setItem('logoUrl', data.data.logoUrl);
            console.log('Logo URL stored:', data.logoUrl);
            this.router.navigateByUrl('/dashboard');
            if (data.otpRequired) {
              alert('OTP GENERATED SUCCESSFULLY');
              this.loginbox = false;
              this.showOtpBox = true;
            } else {
            }
          } else {
            this.utils.showErrorMsg(data.message)
            sessionStorage.clear()
            // alert('OTP GENERATION FAILED');
          }
        });
      } else {
        Object.values(this.validateForm.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }



    getSession(): boolean {
      let token = this.storageService.accessToken;
      return token ? true : false;
    }

    clearCookies(): void {
      // Set the expiry date of the cookies to the past to delete them
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }

    
    ngOnInit(): void {
      const email = localStorage.getItem('email');
      if (email) {
      this.AuthService.signurl(email).subscribe(data => {
        if (data.result === 'success') {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          localStorage.setItem('role', data.userType);
          sessionStorage.setItem('logoUrl', data.data.logoUrl);

          // Set username from API response
        const username = data.username;
        if (username) {
          localStorage.setItem('username', username);
        }

          console.log('Logo URL stored:', data.logoUrl);
          this.router.navigateByUrl('/dashboard');
          if (data.otpRequired) {
            alert('OTP GENERATED SUCCESSFULLY');
            this.loginbox = false;
            this.showOtpBox = true;
          } else {
            // Handle other cases if needed
          }
        } else {
          this.utils.showErrorMsg(data.message);
          sessionStorage.clear();
          // alert('OTP GENERATION FAILED');
        }
      });
    }
 
      // this.AuthService.url.subscribe(data => {
      //   if (data.result === 'success') {
      //     localStorage.setItem('role', data.userType);
      //     sessionStorage.setItem('logoUrl', data.data.logoUrl);
      //     console.log('Logo URL stored:', data.logoUrl);
      //     this.router.navigateByUrl('/dashboard');
      //     if (data.otpRequired) {
      //       alert('OTP GENERATED SUCCESSFULLY');
      //       this.loginbox = false;
      //       this.showOtpBox = true;
      //     } else {
      //     }
      //   } else {
      //     this.utils.showErrorMsg(data.message)
      //     sessionStorage.clear()
      //     // alert('OTP GENERATION FAILED');
      //   }
      // });
      this.storageService.clearStorage();

      this.storageService.clearStorage();
      this.validateForm = this.fb.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        //  otp: ['', [Validators.required]],
      });

      this.otpform = this.fb.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        userOtp: ['', [Validators.required]],
      });
    }




    verifyotp() {

      this.AuthService.verifyOTP({...this.otpform.value,type:this.userType}).subscribe(data => {
        if (data.result === 'success') {
          localStorage.setItem('role',data.userType)

          // this.router.navigateByUrl('/dashboard')
          this.router.navigateByUrl('/dashboard');
          this.utils.showSuccessMsg(data.message)
        } else {
          this.utils.showErrorMsg(data.message)
        }

      });

    }
  }
