import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators,AbstractControl ,ValidatorFn } from '@angular/forms';
import { PhonebookService } from 'src/app/services/phonebook/phonebook.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-view-contacts',
  templateUrl: './view-contacts.component.html',
  styleUrls: ['./view-contacts.component.scss']
})
export class ViewContactsComponent implements OnInit {

  listOfColumn = [
    {
      title: 'Group Name',
      compare: (a: any, b: any) => a.groupName.localeCompare(b.groupName),
      priority: 1
    },
    {
      title: 'Contact Number',
      compare: (a: any, b: any) => a.contactNumber.localeCompare(b.contactNumber),
      priority: 3
    },
    {
      title: 'Contact Name',
      compare: (a: any, b: any) => a.contactName.localeCompare(b.contactName),
      priority: 2
    }
  ];

  listOfData: any;
  listOfGroups: any;
  downloadLink: any;
  selectedContacts: any;

  contactsForm = this.fb.group({
    groupName: ['', Validators.required],
    // contactNumber: ['', [Validators. required, Validators. pattern('^(\\+?\d{1,4}[\s-])?(?!0+\s+,?$)\\d{10,12}\s*,?$')]],
    contactNumber: [
      '',
      [
        Validators.required,
        Validators.pattern('^(\\+?\d{1,4}[\s-])?(?!0+\s+,?$)\\d{10,12}\s*,?$'),
        is12DigitsValidator()
      ],
    ],
    operation: [''],
    pageNumber: ['1']
  })

  addContactsForm = this.fb.group({
    groupName: ['', Validators.required],
    contactNumber: ['', [Validators. required, Validators. pattern('^(\\+?\d{1,4}[\s-])?(?!0+\s+,?$)\\d{10}\s*,?$')]],
    contactName: ['', Validators.required],
    operation: ['addContactNumber']
  })

  userName = { loggedInUsername: this.storageService.username }


  constructor(
    private phonebookService: PhonebookService,
    private storageService: StorageService,
    private utils: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.listAllContacts();
    this.getPhoneBookGroupData();
  }

  resetAddBlacklistForm() {
    this.contactsForm.reset();
  }

  seachData() {
    const data = { ...this.userName, ...this.contactsForm.getRawValue(),pageNumber:1 }
    this.phonebookService.getNumbersOfGroup(data).subscribe(data => {
      this.listOfData = data?.data?.phonebookList;
      this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;

    })
  }


  downloadFile() {
    this.reportService.downloadFile(this.downloadLink);
  }

  deleteGroup() {
    const deleteData = {
      contactIdsToRemove: Array.from(this.selectedContacts.values()),
      loggedInUsername: this.storageService.username,
      operation: "removeContactNumber",
    }
    this.phonebookService.deleteContacts(deleteData).subscribe((response: any) => {
      this.listAllContacts();
      if (response.result === 'Success') {
        this.utils.showSuccessMsg(response.message)
      } else {
        this.utils.showSuccessMsg(response.message)
      }
    });
  }

  listAllContacts() {
    this.contactsForm.patchValue({
      groupName: '',
      contactNumber: '',
      operation: '',
      pageNumber: '1'
    })

    this.phonebookService.listAllContacts().subscribe((response: any) => {
      this.listOfData = response?.data?.phonebookList;
      this.downloadLink = response.data.downloadReportLink ? response.data.downloadReportLink.match(/href='(.*?)'/)[1] : null
    });
  }

  getPhoneBookGroupData() {
    this.phonebookService.getAllPhoneBookGroup().subscribe((response: any) => {
      this.listOfGroups = response?.data?.groupList;
    });
  }
}
function is12DigitsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    // Check if the input is exactly 12 digits long
    if (value && value.length !== 12) {
      return { is12Digits: true };
    }

    return null;
  };
}


