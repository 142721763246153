<!-- <div nz-row nzJustify="space-between">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 15 }">
 -->

<nz-tabset nzTabPosition="left" (nzClick)="resetChildForm()"  *ngIf="!isSmallScreen">
    <nz-tab nzTitle="Group Contacts" (nzClick)="resetChildForm()" *ngIf="userType=='web'" >
        <app-groups ></app-groups>
    </nz-tab>
    <nz-tab nzTitle="Individual Contacts" (nzClick)="resetChildForm()" *ngIf="userType=='web'" >
        <app-contacts></app-contacts>
    </nz-tab>
    <nz-tab nzTitle="View Contacts" (nzClick)="resetChildForm()" *ngIf="userType=='web'">
        <app-view-contacts></app-view-contacts>
    </nz-tab>
    <nz-tab nzTitle="Blacklist" (nzClick)="resetChildForm()" >
        <app-blacklist ></app-blacklist>
    </nz-tab>
</nz-tabset>



<!-- for small screen -->

<nz-tabset *ngIf="isSmallScreen">
    <nz-tab  nzTitle="Group Contacts" *ngIf="userType=='web'" (nzClick)="resetChildForm()">
        <app-groups ></app-groups>
    </nz-tab>
    <nz-tab  nzTitle="Individual Contacts" (nzClick)="resetChildForm()" *ngIf="userType=='web'">
        <app-contacts></app-contacts>
    </nz-tab>
    <nz-tab nzTitle="Blacklist" (nzClick)="resetChildForm()">
        <app-blacklist ></app-blacklist>
    </nz-tab>
  </nz-tabset>








<!-- </div>

    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">

    </div>

</div> -->
