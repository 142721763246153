<div class="form-input">
    <form nz-form [formGroup]="addGroupForm" nzLayout="vertical">
        <div nz-row [nzGutter]="24" nzAlign="middle">
            <div nz-col>

<nz-form-item>
     <nz-form-label>Group Name</nz-form-label>


                <nz-form-control>
                    <input type="text" nz-input formControlName="groupName" placeholder="Enter Group Name"
                    [ngClass]="{'is-invalid': !addGroupForm.controls['groupName'].valid && addGroupForm.controls['groupName'].touched || ! addGroupForm.controls['groupName'].valid&& selected , 'is-valid': addGroupForm.controls['groupName'].valid}" >
                     <div *ngIf=" addGroupForm.controls['groupName'].invalid && addGroupForm.controls['groupName'].touched || ! addGroupForm.controls['groupName'].valid && selected">
                        <small *ngIf=" addGroupForm.controls['groupName'].hasError('required')" class="form-text custom-invalid-feedback"style="color: red;">Field is <b>required</b></small>
                        <small *ngIf="addGroupForm.controls['groupName'].errors?.['pattern'] && addGroupForm.controls['groupName'].touched" class="form-text custom-invalid-feedback"style="color: red;">Please enter a valid name</small>
                     </div>
                </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-label>Description</nz-form-label>
                    <nz-form-control>
                        <input type="text" nz-input formControlName="groupDescription"
                         placeholder="Description" >

                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-control>
                    <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
                </nz-form-control>
            </div>
            <div nz-col>
                <nz-form-control>
                    <button nz-button (click)="resetForm()">Clear</button>
                </nz-form-control>
            </div>
        </div>
    </form>
</div>


<app-simple-table [listOfData]="listOfData" [pagination]="true" [listOfColumn]="listOfColumn" [type]="'groups'"
    (deleteTriggred)="deleteGroup($event)"></app-simple-table>
