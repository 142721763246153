import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardGuard } from 'src/app/services/auth-guard/auth-guard.guard';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isSmallScreen = false;
  isCollapsed = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }


  visible: boolean = false;
  jj:boolean = false;
  dasboardCount: any;
  userName: string = '';
  userAllAccount:any={};
  selectedValue = '';
  constructor(private storageService: StorageService,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private authGuard: AuthGuardGuard,
    private router: Router) { 
      
    }

  ngOnInit(): void {
    
    this.getDashboardStats();
    this.userName = this.storageService.username;
    this.selectedValue = this.storageService.username
    this.userAllAccount=this.storageService.userAllAccount
    this.checkScreenSize();
   
  }
  private checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }
  // checkScreenSize() {
  //   this.isCollapsed = window.innerWidth <= 768; // Set isCollapsed to true if screen width is less than or equal to 768px
  // }

  getDashboardStats() {
    this.dashboardService.getDashboardData().subscribe(data => {
      this.dasboardCount = data.data;

    })
  }
  profile() {
    this.router.navigate(['/profile']);
}
switchUser(){
let userDetail = this.selectedValue.split('||~~||')
  sessionStorage.setItem('username',userDetail[0])
  sessionStorage.setItem('role',userDetail[2])
  sessionStorage.setItem('userId',userDetail[1])

  // this.storageService.user = this.selectedValue
  // alert(this.storageService.userId)


  window.location.reload()
}

// set username(value:any){
//  this.selectedValue = value
// }
  exitApp() {
    this.authService.signOut().subscribe(() => {
      this.authGuard.loggedInUser.next(false);
      this.router.navigateByUrl('/');
    });
  }
  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    console.log('Menu is collapsed:', this.isCollapsed);
  }
}
