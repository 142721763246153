import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import { DatePipe } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

@Component({
  selector: 'app-re-run',
  templateUrl: './re-run.component.html',
  styleUrls: ['./re-run.component.scss'],
  providers: [DatePipe]
})
export class ReRunComponent implements OnInit {
  @Input() rowData: any;
  // isVisible = false;
  // data: any;
  // date = new Date();
  // selectedDate = new Date();
  // timeDefaultValue: Date | undefined;
  // rerunForm: FormGroup = this.fb.group({
  //   DateTime: '' ,
  //   isReRun:"Y"
  // });

  constructor(
    // public datepipe: DatePipe,
    // private route: ActivatedRoute,
    // private storageService: StorageService,
    // private utilService: UtilsService,
    // private utilsService: UtilsService,
    // private campaignService: CampaignService,
    // private fb: FormBuilder,
    // private reportService: ReportService
  ) { }
  // disabledDate = (current: Date): boolean => false;
  // disabledDate = (current: Date): boolean => {
  //   const today = new Date();
  //   return current < today;
  // };


  // showModal(): void {
  //   this.isVisible = true;
  // }

  // handleOk(): void {
  //   this.isVisible = false;
  // }

  // handleCancel(): void {
  //   this.isVisible = false;
  // }











  ngOnInit(): void {
  //   const currentTime = this.getCurrentTime();
  //   this.rerunForm = this.fb.group({
  //     DateTime: [this.data?.Date],
  //     isReRun:"Y",
  //   });
  // }
  // getCurrentTime(): string {
  //   const now = new Date();
  //   const hours = now.getHours();
  //   const minutes = now.getMinutes();
  //   const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
  //     .toString()
  //     .padStart(2, '0')}`;
  //   return formattedTime;
  // }
  // submitForm(){
  //   const dt = {
  //     DateTime: this.rerunForm.get('DateTime')?.value,
  //     campaignName: this.data?.campaignName,
  //     isReRun: "Y",
  //     senderId:this.data?.senderId,
  //     msgText: this.data?.massageText,
  //     username: this.storageService.username,
  //     campaignType:this.data?.campaignType,
  //     campaignId:this.data?.campaignId,
  //     massageCount:this.data?.massageCount,
  //     createdDate:this.data?.createdDate,
  //     isProcessed:this.data?.isProcessed,
  //     entityId:this.data?.entityId,
  //     // dltTemplateId:this.data.dltTemplateId,
  //     serviceType:this.data.serviceType,
  //     isShortUrlSelected:this.data.isShortUrlSelected,
  //     msgType: this.data?.msgType,
  //     perMsgCredit: this.data?.perMessageCredit,
  //     shortUrlName: this.data?.shortUrlName,
  //     uploadedBulkfileName: this.data?.serverFileName,
  //     serviceSubType: this.data?.serviceSubType,

  //     // scheduleInfo: this.data?.scheduleInfo,
  //     // splitFile: this.data?.splitFile,
  //     // splitPart: this.data?.splitPart,

  //   };
  //   this.campaignService.sendBulkSMS(dt).subscribe(data => {
  //     // this.resetForm();
  //     // this.isVisible = false;
  //     // this.isOkLoading = false;
  //     if (data.result === "Success") {
  //       this.utilService.showSuccessMsg(data.message);
  //     } else {
  //       this.utilService.showErrorMsg(data.message);
  //     }
  //   }, err => {
  //     this.utilService.showErrorMsg('Something Went Wrong');
  //   });
  // }
  // range(start: number, end: number): number[] {
  //   const result: number[] = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // }
  // disabledDateTime: DisabledTimeFn = (current: Date | null | Date[]) => {
  //   const today = new Date();
  //   let currentHour: number | undefined;

  //   if (current instanceof Array) {
  //     currentHour = current[0].getHours();
  //   } else {
  //     currentHour = current?.getHours();
  //   }

  //   const isToday = current instanceof Array ? current[0].getDate() === today.getDate() : current?.getDate() === today.getDate();

  //   if (!isToday) {
  //     return undefined;
  //   }

  //   return {
  //     nzDisabledHours: () => {
  //       if (today.getHours() === 0) {
  //         return this.range(0, 24).splice(1);
  //       }
  //       return this.range(0, 24).splice(0, today.getHours());
  //     },
  //     nzDisabledMinutes: () => {
  //       if (today.getHours() === currentHour) {
  //         return this.range(0, 60).splice(0, today.getMinutes());
  //       }
  //       return [];
  //     },
  //     nzDisabledSeconds: () => {
  //       if (today.getSeconds() === 0) {
  //         return this.range(0, 60).splice(1);
  //       }
  //       return this.range(60, 60).splice(0, today.getSeconds());
  //     }
  //   };
  // };

}

}
