import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CreditCountPipe } from 'src/app/pipe/credit-count.pipe';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { PhonebookService } from 'src/app/services/phonebook/phonebook.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TemplateService } from 'src/app/services/templates/template.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CAMPAIGN_NAME_HINT, CAMPAIGN_NAME_REGEX, PLAIN_TXT_REGEX } from 'src/environments/environment';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';

@Component({
  selector: 'app-group-campaign',
  templateUrl: './group-campaign.component.html',
  styleUrls: ['./group-campaign.component.scss']
})
export class GroupCampaignComponent implements OnInit {

  timeDefaultValue: Date | undefined;

  selectedDate = new Date();


  groupCampaignForm = this.fb.group({
    senderId: [null, [Validators.required]],
    entityId: ['', Validators.required],
    loggedInUserName: this.storageService.username,
    campaignName: [null, [Validators.required, Validators.pattern(CAMPAIGN_NAME_REGEX)]],
    serviceType: 'trans',
    serviceSubType: 'service-implicit',
    dltTemplateId: ['', [Validators.required]],
    isShortUrlSelected: "N",
    groupName: ['', Validators.required],
    msgPart: "single",
    msgText: ['', [Validators.required]],
    msgType: "plain",
    perMsgCredit: "",
    shortUrlName: [null],
    templateName: [''],

    contentTemplateId: '',
    isScheduledSMS: "no",
    isTemplateSelected: "no",
    scheduleDateTime: ''
  });

  messageType: string = 'trans';
  note: string = CAMPAIGN_NAME_HINT;
  templateSelected: any;
  listOfSenderIds: any;
  listOfTemplates: any;
  listOfShortUrl: any;
  senderIdSelected: any;
  isShortUrlSelected: string = 'N';
  messageText: string = '';
  listOfURLSelected: string[] = [];
  listOfGroups: any;
  isScheduledSMS: string = 'no';
  msgTextLength:number=0;


  disabledDate = (date: Date): boolean => {
    const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1); // get yesterday's date
  return date < yesterday;
}


  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private templateService: TemplateService,
    private campaignService: CampaignService,
    private reportService: ReportService,
    private utilService: UtilsService,
    private creditCountPipe: CreditCountPipe,
    private phonebookService: PhonebookService
  ) {
    const currentDate = new Date();
    const fifteenMinutesAgo = new Date(currentDate.getTime() + 5 * 60000);
    this.selectedDate = fifteenMinutesAgo;
    }


    disabledDateTime: DisabledTimeFn = (current: Date | null | Date[]) => {
      const today = new Date();
      let currentHour: number | undefined;

      if (current instanceof Array) {
        currentHour = current[0].getHours();
      } else {
        currentHour = current?.getHours();
      }

      const isToday = current instanceof Array ? current[0].getDate() === today.getDate() : current?.getDate() === today.getDate();

      if (!isToday) {
        return undefined;
      }

      return {
        nzDisabledHours: () => {
          if (today.getHours() === 0) {
            return this.range(0, 24).splice(1);
          }
          return this.range(0, 24).splice(0, today.getHours());
        },
        nzDisabledMinutes: () => {
          if (today.getHours() === currentHour) {
            return this.range(0, 60).splice(0, today.getMinutes() + 15);
          }
          return [];
        },
        nzDisabledSeconds: () => {
          if (today.getSeconds() === 0) {
            return this.range(0, 60).splice(1);
          }
          return this.range(60, 60).splice(0, today.getSeconds());
        }
      };
    };

    range(start: number, end: number): number[] {
      const result: number[] = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    }


  ngOnInit(): void {
    this.getSenderIdsByMsgType();
    this.getAllShortUrl();
    this.genCampaignName();
    this.getPhoneBookGroupData();
  }
  lengthWithoutNewlines(str: string) {
    return str.replace(/\r/g, '').replace(/\n/g, '').length;
  }
  msgLength(){
    var sectionToCheck = this.groupCampaignForm.get('msgText')?.value;
   var allFoundCharacters = sectionToCheck.match(/[\[\]\{\}\\|\^€\~]/g);
   var splCharCount = (allFoundCharacters) ? allFoundCharacters.length : 0;//count
   var charCount = this.lengthWithoutNewlines(this.groupCampaignForm.get('msgText')?.value) + parseInt(splCharCount);
   this.msgTextLength = charCount;
 }


  onItemSelect(shortname: any, field: any) {
    var cursorPos = this.campaignService.getCaretPos(field);
    var msgText = this.groupCampaignForm.value.msgText;
    var textBefore = msgText.substring(0, cursorPos);
    var textAfter = msgText.substring(cursorPos, msgText.length);
    var shortObj = this.listOfShortUrl.filter((p: any) => p.name == shortname)[0];
    this.groupCampaignForm.patchValue({
      // msgText: textBefore + "" + shortObj.hostName + "/xxxxxx" + textAfter
      msgText: textBefore + ""   + textAfter + shortObj.hostName + "/xxxxxx"
    });
    // this.onMsgTextKeyUp(msgText);
  }

  removeShortUrlInMsgText(value: any) {
    var msgText = this.groupCampaignForm.value.msgText;
    let unSelecteData = value;
    var unSelecteData_index = this.listOfURLSelected.findIndex((item: any) => item === unSelecteData);
    var shortObj = this.listOfShortUrl.find((p: any) => p.name === unSelecteData);
    console.log(shortObj)
    let finding_text = shortObj.hostName + "/xxxxxx";
    console.log(finding_text)

    let index = this.campaignService.getIndex(msgText, finding_text, (unSelecteData_index + 1))
    console.log(index)

    // if (index === -1) {
    // } else {
    //   this.listOfURLSelected = [...this.selectedItems]
    // }
    console.log(msgText)

    let new_text = msgText.slice(0, index) + msgText.slice((index + finding_text.length));
    console.log(new_text)

    this.groupCampaignForm.patchValue({
      msgText: new_text
    });
  }

  onItemDeSelect(event: any) {
    this.removeShortUrlInMsgText(event)
  }

  onShortUrlSelect(event: string[], field: any) {
    if (this.listOfURLSelected.length < event.length) {
      const newItem = event.filter((x: any) => !this.listOfURLSelected.includes(x))[0];
      console.log("newItem", newItem)
      this.listOfURLSelected = event;
      this.onItemSelect(newItem, field);
    } else {
      const removedItem = this.listOfURLSelected.filter((x: any) => !event.includes(x))[0];
      console.log("removedItem", removedItem)
      this.listOfURLSelected = this.listOfURLSelected.filter((x: any) => x !== removedItem);
      this.removeShortUrlInMsgText(removedItem);
    }
  }

  submitForm() {
    if (this.groupCampaignForm.valid) {

      let rawData = this.groupCampaignForm.getRawValue();
      if (this.groupCampaignForm.valid) {
        if (this.campaignService.validateCampaignForm(rawData)) {
          this.submitQuickCampaign();
        }
      }

    } else {
      Object.values(this.groupCampaignForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  submitQuickCampaign() {

    let params = this.groupCampaignForm.getRawValue();
    params.perMsgCredit = this.creditCountPipe.transform(params.msgText);
    params.contentTemplateId = params.templateName?.templateId;
    delete params.templateName;
    if (params.serviceType !== "service") {
      delete params.serviceSubType;
    }

    if (params.isScheduledSMS !== "no") {
      let schDate = this.utilService.formatDate(params.scheduleDateTime, 'YYYY-MM-dd');
      params.scheduleDate = schDate;
      params.scheduleHour = new Date(params.scheduleDateTime).getHours();
      params.scheduleMinute = new Date(params.scheduleDateTime).getMinutes();
    }

    delete params.scheduleDateTime;

    this.campaignService.sendGroupSMS(params).subscribe(data => {
      this.resetForm();
      if (data.result === "Success") {
        this.utilService.showSuccessMsg(data.message);
      } else {
        this.utilService.showErrorMsg(data.message);
      }
    }, err => {
      this.utilService.showErrorMsg('Something Went Wrong');
    });
  }


  resetForm() {
    this.groupCampaignForm.reset()
    this.groupCampaignForm.patchValue({
      serviceType: 'trans',
      serviceSubType: 'service-implicit',
      isShortUrlSelected: "N",
      msgPart: "single",
      msgType: "plain",
      isScheduledSMS: "no",
      isTemplateSelected: "no",
      msgLength:this.msgTextLength = this.groupCampaignForm.get('msgText')?.value,
      loggedInUserName: this.storageService.username,
    });
    this.genCampaignName();
  }

  genCampaignName() {
    this.groupCampaignForm.patchValue({
      campaignName: this.campaignService.genCampaignName()
    })
  }

  // Api Calls

  getAllShortUrl() {
    this.reportService.listAllShortUrl().subscribe(data => {
      this.listOfShortUrl = data.data.shortUrlList;
    })
  }

  getSenderIdsByMsgType(type: string = this.messageType, subType: string = 'service-implicit') {
    let sub = type === 'others' ? subType : '';
    this.templateService.getSenderIdByMsgType(type, sub).subscribe(data => {
      this.listOfSenderIds = data.data.senderIdList;
    })
  }

  getTemplates(type: string, senderId: any) {
    let params = { "loggedInUserName": this.storageService.username,
    "messageSubType": (this.messageType=='others')?this.groupCampaignForm.get('serviceSubType')?.value:'',
     "messageType": (this.messageType=='others')?'service':this.messageType,
      "senderId": senderId };
    this.campaignService.getTemplatesBySenderId(params).subscribe(data => {
      this.listOfTemplates = data.data.contentTemplateList;
    })
  }

  getPhoneBookGroupData() {
    this.phonebookService.getAllPhoneBookGroup().subscribe((response: any) => {
      this.listOfGroups = response?.data?.groupList;
    });
  }

}
