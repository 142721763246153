<div nz-modal-header>
  <!-- <nz-modal [(nzVisible)]="isVisible" nzTitle="The first Modal" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzFooter]="null">
    <form [formGroup]="rerunForm" class="horizontal-form" *nzModalContent>
      < <div class="md-3 row">
        <label for="Date" class="col-sm-3 col-form-label"><b>Select Date</b></label>
        <div class="col-sm-6">
          <nz-date-picker formControlName="Date" class="form-control"></nz-date-picker>
        </div>
      </div> 
      <br>
      <nz-form-item>
        <p>{{ rowData?.campaignType }}</p>
      </nz-form-item>
      <nz-form-item >
        <nz-form-label class="col-sm-5 col-form-label"><b>Schedule Date & Time</b></nz-form-label>
        <nz-form-control class="col-sm-2 " style="margin-top: 2%;">
          <nz-date-picker
          nzFormat="yyyy-MM-dd HH:mm"
          [nzDisabledDate]="disabledDate"
          [nzDisabledTime]="disabledDateTime"
          [(ngModel)]="selectedDate"
          formControlName="DateTime"
          [nzShowNow]="false"
          [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue, nzFormat: 'HH:mm' }">
      </nz-date-picker>

        </nz-form-control>
    </nz-form-item>
      <div class="md-3 row">
        <label for="Time" class="col-sm-3 col-form-label"><b>Select Time</b></label>
        <div class="col-sm-6">
          <nz-time-picker formControlName="Time" class="form-control" ></nz-time-picker>
        </div>
      </div> -->
      <br>
      <br>
      <!-- <div class="md-3 row" style="margin-left: 37%;"> 
        <nz-form-item class="centered-form-item">
            <nz-form-control nzSm="12" nzOffset="9">
                <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
                <button nz-button style="margin-left: 1%;">Cancel</button>
            </nz-form-control>
        </nz-form-item>

    </form>
  </nz-modal> 
  </div>
</div> -->
