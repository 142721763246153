import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BlacklistComponent } from './blacklist/blacklist.component';
import { GroupsComponent } from './groups/groups.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ViewContactsComponent } from './view-contacts/view-contacts.component';
@Component({
  selector: 'app-phonebook',
  templateUrl: './phonebook.component.html',
  styleUrls: ['./phonebook.component.scss']
})
export class PhonebookComponent implements OnInit {
  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }
userType:any='';
  constructor(
    // public blacklistComponent:BlacklistComponent
  ) {
    if(localStorage.getItem('role')) {
      this.userType = localStorage.getItem('role');
    }
   }

  ngOnInit(): void {
    this.checkScreenSize()
  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  @ViewChild(BlacklistComponent, { static: true })
  blacklistComponent!: BlacklistComponent;
  @ViewChild(GroupsComponent, { static: true })
  GroupsComponent!: GroupsComponent;
  @ViewChild(ContactsComponent, { static: true })
  ContactsComponent!: ContactsComponent;
  @ViewChild(ViewContactsComponent, { static: true })
  ViewContactsComponent!: ViewContactsComponent;



  resetChildForm() {
    this.blacklistComponent.resetAddBlacklistForm();
    this.blacklistComponent.description='';
    this.GroupsComponent.resetAddBlacklistForm();
    this.ContactsComponent.resetAddBlacklistForm();
    this.ViewContactsComponent.resetAddBlacklistForm();

  }
}
