<div nz-row nzJustify="space-between" nzGutter="48">
    <div nz-col nzSpan="15" nzOffset="2">
        <!-- <h4 nz-typography>Create New Short URL</h4> -->

        <div class="form-input">
            <form nz-form [formGroup]="viewshortUrl">
                <nz-form-item>
                    <nz-form-label class="txt-left1" [nzSm]="7" >Name</nz-form-label>
                    <nz-form-control [nzSm]="15">
                        <input nz-input  placeholder="Name" formControlName="name" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left1"  [nzSm]="7">Is Active</nz-form-label>
                    <nz-form-control [nzSm]="16">
                        <nz-radio-group  formControlName="isActive">
                            <label nz-radio nzValue="Y">Yes</label>
                            <label nz-radio nzValue="N">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left1" [nzSm]="7" >Is Dynamic</nz-form-label>
                    <nz-form-control [nzSm]="15">
                        <nz-radio-group formControlName="isDynamic">
                            <label nz-radio nzValue="Y">Yes</label>
                            <label nz-radio nzValue="N">No</label>
                        </nz-radio-group>
                        <br />
                        <small><i>if is dynamic is selected different short url will be sent on each number</i></small>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left1" [nzSm]="7" >Select Domain</nz-form-label>
                    <nz-form-control [nzSm]="15">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Status" formControlName="domainName">
                            <nz-option [nzLabel]="host.domainName" [nzValue]="host.domainName"
                                *ngFor="let host of listActiveHost"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item >
                    <nz-form-label class="txt-left1" [nzSm]="7" >Long URL</nz-form-label>
                    <nz-form-control [nzSm]="15" >
                        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                            <input type="text" nz-input formControlName="longUrlValue" />
                        </nz-input-group>
                        <ng-template #addOnBeforeTemplate>
                            <nz-select [ngModel]="'http://'" formControlName="longUrl" style="width:100px;">
                                <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                                <nz-option nzLabel="https://" nzValue="https://"></nz-option>
                            </nz-select>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label class="txt-left1" [nzSm]="7">Callback URL</nz-form-label>
                    <nz-form-control [nzSm]="15">
                        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                            <input type="text" nz-input formControlName="callbackUrlValue" />
                        </nz-input-group>
                        <ng-template #addOnBeforeTemplate>
                            <nz-select [ngModel]="'http://'" formControlName="callbackUrl">
                                <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                                <nz-option nzLabel="https://" nzValue="https://"></nz-option>
                            </nz-select>
                        </ng-template>
                        <small style="word-break: break-all;"><i><b>Sample URL</b> :
                                google.com?mobile=%MOBILE%&camp=%CAMPAIGN_NAME%&rectime=%RECTIME%&circle=%CIRCLE%&operator=%OPERATOR%</i></small>
                    </nz-form-control>
                </nz-form-item>
                <!-- <nz-form-item>
                    <nz-form-label class="txt-left1" [nzSm]="7" >Short URL Code</nz-form-label>
                    <nz-form-control [nzSm]="15">
                        <input nz-input class="ji" placeholder="Short URL Code" [disabled]="true" formControlName="shortCode"
                            [(ngModel)]="shortCode" />
                        <small><i>Short URL code will be generated only after hitting submit button</i></small>
                    </nz-form-control>
                </nz-form-item> -->
                <nz-form-item>
                    <nz-form-control style="margin-left: 60%;">
                        <button nz-button nzType="primary" mb-3 (click)="submitForm()">Submit</button>
                        <button nz-button (click)="resetForm()">Clear</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>

    </div>
