<div nz-row nzJustify="space-between" nzGutter="48">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }">
<!-- <div nz-row nzJustify="space-between"> -->
<!-- <!DOCTYPE html>
<html lang="en"> -->

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>


<body>

    <button  style="margin-left: 93%;" class="btn btn-primary" (click)="htmltoPDF()" >Download</button>
    <div class="container-fluid"  id="parentdiv">
        <div class="text-center">
            <br>
            <h3 >HTTP APIs</h3>

        </div>

        <br>
        <hr>
        <div>
            <ol>
                <li>
                    <span><b>Push API with Corelation & PEID</b>></span>
                    <h6>Request Type : GET</h6>
                    <!-- <h6>Endpoint : {{a}}BASE_URL{{b}}/send</h6> -->
                    <h6>Sample Request with plain text</h6>
                    <div class="ab"><small>https://domainname/pushapi/sendbulkmsg?username=XXXXX&dest=XXXXXXXXXX&apikey=XXXXXXXXXXXXX&signature=XXXXXX&msgtype=PM&msgtxt=testmessage&entityid=XXXXXXXX&templateid=XXXXXXXX
                        </small>
                        <br>
                        </div>
                        <h6>Sample Request with Unicode text</h6>
                        <div class="ab"><small>https://domainname/pushapi/sendbulkmsg?username=XXXXX&dest=XXXXXXXXXX&apikey=XXXXXXXXXXXXX&signature=XXXXXX&msgtype=UC&msgtxt=testmessage&entityid=XXXXXXXX&templateid=XXXXXXXX
                             </small></div>
                    <div style="margin-top: 10px;">
                        <h6>Response :- </h6>
                        <div>
                            {{a}}<br> code	"6001",<br>desc	"Message received by platform.",<br>reqId	"XXXXXXXXXXXXXXXXXXXX",<br>time	"XXXXXXXXXXXXXXXXXXXX"
                            ,<br>partMessageIds,<br>0	"XXXXXXXXXXXXXXXXXXXX",<br>totalMessageParts	1<br>{{b}}
                            <hr>
                        </div>
                    </div>
                </li>
                <li>
                    <span>API with DLT ID</span>

                    <div class="ab"><small>https://domainname/pushapi/sendbulkmsg?username=XXXXX&dest=XXXXXXXXXX&apikey=XXXXXXXXXXXXX&signature=XXXXXX&msgtype=PM&msgtxt=testmessage&entityid=XXXXXXXX&templateid=XXXXXXXX

                        </small>
                    </div>
                    <div style="margin-top: 10px;">
                        <h6>Response :- </h6>
                        <div>
                          {{a}}<br> code	"6001",<br>desc	"Message received by platform.",<br>reqId	"XXXXXXXXXXXXXXXXXXXX",<br>time	"XXXXXXXXXXXXXXXXXXXX"
                          ,<br>partMessageIds,<br>0	"XXXXXXXXXXXXXXXXXXXX",<br>totalMessageParts	1<br>{{b}}
                          <hr>
                      </div>
                    </div>
                </li>
                <hr>
                <li>
                    <span>API without DLT ID</span>

                    <div class="ab"><small>https://domainname/pushapi/sendbulkmsg?username=XXXXX&dest=XXXXXXXXXX&apikey=XXXXXXXXXXXXX&signature=XXXXXX&msgtype=PM&msgtxt=testmessage

                        </small>
                    </div>
                    <div style="margin-top: 10px;">
                        <h6>Response :- </h6>
                        <div>
                          {{a}}<br> code	"6001",<br>desc	"Message received by platform.",<br>reqId	"XXXXXXXXXXXXXXXXXXXX",<br>time	"XXXXXXXXXXXXXXXXXXXX"
                          ,<br>partMessageIds,<br>0	"XXXXXXXXXXXXXXXXXXXX",<br>totalMessageParts	1<br>{{b}}
                          <hr>
                      </div>
                    </div>
                </li>
                <!-- <hr> -->
                <!-- <li>
                    <span>API without Unicode</span>
                    <span>API without DLT ID</span>
                    <h6>Request Type : GET</h6>
                    <h6>Endpoint : {{a}}BASE_URL{{b}}//</h6>
                    <h6>Sample Request</h6>
                    <div class="ab"><small>https://Domainname/multiSend?username=XXXX&apiKey=12345&from=ABCDEF&to=XXXXXXXXXX&text=xxxx

                        </small>
                    </div>
                    <div style="margin-top: 10px;">
                        <h6>Response :- </h6>
                        <div>
                          {{a}}<br> code	"6001",<br>desc	"Message received by platform.",<br>reqId	"XXXXXXXXXXXXXXXXXXXX",<br>time	"XXXXXXXXXXXXXXXXXXXX"
                          ,<br>partMessageIds,<br>0	"XXXXXXXXXXXXXXXXXXXX",<br>totalMessageParts	1<br>{{b}}
                          <hr>
                      </div>
                    </div>
                </li> -->
                <!-- <hr> -->
            </ol>
            <div class="container-fluid">
                <h4 class="ml-5">JSON API</h4>
                <span class="ml-5">Please find below JSON API for one2One & one2Many.</span>
                <ol>
                    <li>
                        <span>With Principal Entity ID one2many:-</span>
                        <span>curl -k -H 'Content-Type:application/json' -H 'Accept:application/json' -d
                            '{{a}}<br>"credentials":{{a}}"password":"XXXXX","user":"XXXXX"{{b}},<br>"options":
                            {{a}}"dltContentId":"XXXXX","dltPrincipalEntityId":"XXXXX"{{b}},<br>"from":"XXXXX",<br>"messageText":"TEXT",<br>"recpients":[<br>{{a}}"corelationId":"XXXXX","mobile":XXXXX{{b}},<br>{{a}}"corelationId":"XXXXX","mobile":XXXXX{{b}}<br>],<br>"unicode":"false"{{b}}'
                            <br>https://domainname/one2Many
                        </span>
                        <span>
                            <h6>Example:-</h6><br>
                            API :- http://{{c}}ServerUrl/ServerIp>:{{c}}Port>/pushapi/fe/api/v1/one2Many<br>
                            <h6>Request parameter :- </h6><br>
                            {{a}}"credentials":{{b}}"apiKey":"12345","user":"firstuser"{{b}},<br>
                            "options": {{a}}"dltContentId":"XX","dltPrincipalEntityId":"123456"{{b}},<br>
                            "from":"TSTSMS",<br>
                            "messageText":"XXXX",<br>
                            "recpients":[{{a}}"corelationId":"","mobile":"xxxx"{{b}},{{a}}"corelationId":"","mobile":"xxxx"{{b}}],
                            "unicode":"false"{{b}}
                        </span>
                        <h6>Response :- </h6>
                        <span>
                            {{a}}<br>
                            "submitResponses": [<br>
                            {{a}}<br>
                            "transactionId": "XXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1,<br>
                            "dltPEId": "XXXX"<br>
                        {{b}},<br>
                            {{b}}<br>
                            "transactionId": "XXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1,<br>
                            "dltPEId": "XXXXX"<br>
                            {{b}}<br>
                            ]<br>
                            {{b}}<br>
                        </span>
                        <hr>

                    </li>
                    <li>
                        <span> Without Principal Entity ID one2many:-</span>
                        <span>curl -k -H 'Content-Type:application/json' -H 'Accept:application/json' -d
                            '{{a}}"credentials":{{a}}"password":"XXXXX","user":"XXXXX"{{b}},"options":
                            {{a}}"dltContentId":"XXXXX"{{b}},"from":"XXXX","messageText":"XXXXX","recpients":[{{a}}"corelationId":"XXXXX","mobile":XXXXX{{b}},{{a}}"corelationId":"XXXXX","mobile":
                            XXXXX{{b}}],"unicode":"false"{{b}}' https://domainname/one2Many
                        </span>
                        <span>
                            <h6>Example:-</h6><br>
                            API :- http://{{c}}ServerUrl/ServerIp>:{{c}}Port>/pushapi/fe/api/v1/one2Many<br>
                            <h6>Request parameter :- </h6><br>
                            {{a}}"credentials":{{a}}"apiKey":"12345","user":"firstuser"{{b}},<br>
                            "options": {{a}}"dltContentId":"77"{{b}},<br>
                            "from":"TSTSMS",<br>
                            "messageText":"XXXXX",<br>
                            "recpients":[{{a}}"corelationId":"","mobile":"XXXXXXXXXXX"{{b}},{{a}}"corelationId":"","mobile":"XXXXXXXXXX"{{b}}],<br>
                            "unicode":"false"{{b}}<br>
                        </span>
                        <h6>Response :- </h6>
                        <span>
                            {{a}}<br>
                            "submitResponses": [<br>
                            {{a}}<br>
                            "transactionId": "XXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1<br>
                            {{b}},<br>
                            {{a}}<br>
                            "transactionId": "XXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1<br>
                            {{b}}<br>
                            ]<br>
                            {{b}}<br>
                        </span>
                        <hr>


                    </li>
                    <li>
                        <span> With Principal Entity ID one2One:-</span>
                        <span>curl -k -H 'Content-Type:application/json' -H 'Accept:application/json' -d
                            '{{a}}"credentials": {{a}}<br>"password": "XXXXX",<br>"user":
                            "XXXXX"<br>{{b}},<br>"options":{{a}}"dltPrincipalEntityId":XXXXX{{b}},"from": "XXXXX",<br>"shortMessages":
                            [{{a}}"message": "XXXXX","recipient":XXXXXXXXXX,"corelationId":
                            XXXXX,"dltContentId":"XXXXX"{{b}},{{a}}"message": "XXXXX","recipient": XXXXX,"corelationId":
                            XXXXX,"dltContentId":"XXXXX"{{b}}],"unicode":false{{b}}' https://domainname/one2One

                        </span>
                        <span>
                            <h6>Example:-</h6><br>
                            API :- http://{{c}}ServerUrl/ServerIp>:{{c}}Port>/pushapi/fe/api/v1/one2One<br><br>
                            <h6>Request parameter :- </h6><br>
                            {{a}}"credentials": {{a}}"apiKey":"12345","user": "firstuser"{{b}},<br>
                            "options":{{a}}"dltPrincipalEntityId":"XXXXX"{{b}},"from": "TSTSMS",<br>
                            "shortMessages": [{{a}}"message": "XXXX
                            ","recipient":"XXXXXXXXXX","corelationId": "","dltContentId":"77"{{b}},{{a}}"message": "XXXXX
                           ","recipient": XXXXXXXXX,"corelationId":
                            "","dltContentId":"77"{{b}}],<br>
                            "unicode":"false"{{b}}<br>
                        </span>
                        <h6>Response :- </h6>
                        <span>
                            {{a}}<br>
                            "submitResponses": [<br>
                            {{a}}<br>
                            "transactionId": "XXXXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1,<br>
                            "dltPEId": "XXXXXX"<br>
                            {{b}},<br>
                            {{a}}<br>
                            "transactionId": "XXXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1,<br>
                            "dltPEId": "XXXXXXX"<br>
                            {{b}}<br>
                            ]<br>
                            {{b}}<br>

                        </span>
                        <hr>


                    </li>
                    <li>
                        <span>Without Principal Entity ID one2One:-</span>
                        <span>curl -k -H 'Content-Type:application/json' -H 'Accept:application/json' -d
                            '{{a}}"credentials": {{a}}"password": "XXXXX","user": "XXXXX"{{b}} ,"from": "XXXXX","shortMessages":
                            [{{a}}<br>"message": "XXXXX",<br>"recipient":XXXXX,<br>"corelationId": XXXXX,<br>"dltContentId":""{{b}},{{a}}"message":
                            "","recipient": XXXXX,"corelationId": XXXXX,"dltContentId":"XXXXX"{{b}}],"unicode":false{{b}}'
                            https://domainname/one2One


                        </span>
                        <span>
                            <h6>Example:-</h6><br>
                            API :- http://{{c}}ServerUrl/ServerIp>:{{c}}Port>/pushapi/fe/api/v1/one2One<br>
                            <h6>Request parameter :- </h6><br>
                            {{a}}"credentials": {{a}}"apiKey":"12345","user": "firstuser"{{b}},<br>
                            "options":{{a}}{{b}},"from": "TSTSMS",<br>
                            "shortMessages": [{{a}}"message": "XXXXX
                            ",<br>"recipient":"XXXXXXXXX",<br>"corelationId": "XXXX",<br>"dltContentId":"77"{{b}}<br>,{{a}}"message": "XXXXX
                           ","recipient": XXXXXXXXX,"corelationId":
                            "","dltContentId":"77"{{b}}],<br>
                            "unicode":"false"{{b}}<br>

                        </span>
                        <h6>Response :- </h6>
                        <span>
                            {{a}}<br>
                            "submitResponses": [<br>
                            {{a}}<br>
                            "transactionId": "XXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1<br>
                            {{b}},<br>
                            {{a}}<br>
                            "transactionId": "XXXXXX",<br>
                            "state": "SUBMIT_ACCEPTED",<br>
                            "statusCode": "200",<br>
                            "description": "Message accepted successfully",<br>
                            "pdu": 1<br>
                            {{b}}<br>
                            ]<br>
                            {{b}}<br>

                        </span>
                        <hr>


                    </li>
                </ol>
                <div>
                    <div class="container-fluid ml-5">
                    <span>
                        http://{{c}}ServerUrl/ServerIp>:{{c}}Port>/pushapi/fe/api/v1/one2One<br>
                        < OneToOnePush><br>
                            < credentials><br>
                                < apiKey>xxxxxxxxxxx< / apiKey><br>
                                < user>firstuser< / user><br>
                            < / credentials><br>
                            < from>TSTSMS< /from><br>
                                    < shortMessages><br>
                                        < message>Test Message1< /message><br>
                                                < recipient>xxxx< / recipient><br>
                                                < corelationId>1< / corelationId><br>
                                                < dltContentId>1234< / dltContentId><br>
                                            < / shortMessages><br>
                                            < shortMessages><br>
                                                < message>XXXXXX< / message><br>
                                                    < recipient>xxxx< /recipient><br>
                                                            < corelationId>2< / corelationId><br>
                                                            < dltContentId>1234< / dltContentId><br>
                                                        < / shortMessages><br>
                                                        < unicode>true< / unicode><br>
                                                    < / OneToOnePush><br>

                                                    <h6>Response :- </h6>
                                                    <? xml version="1.0" encoding="UTF-8" standalone="yes"?><br>
                                                    < PushResponse><br>
                                                        < submitResponsesList><br>
                                                            < submitResponses><br>
                                                                < transactionId> xxxx< /
                                                                        transactionId><br>
                                                                        < state>SUBMIT_ACCEPTED< / state><br>
                                                                                < description>Message accepted
                                                                                    successfully< / description><br>
                                                                                        < pdu>1< / pdu><br>
                                                                                                < / submitResponses><br>
                                                                                                    < submitResponses>
                                                                                                        <br>
                                                                                                        < transactionId> xxxx

                                                                                                            < /
                                                                                                                transactionId>
                                                                                                                <br>
                                                                                                                < state>
                                                                                                                    SUBMIT_ACCEPTED
                                                                                                                    < /
                                                                                                                        state>
                                                                                                                        <br>
                                                                                                                        <
                                                                                                                            description>
                                                                                                                            Message
                                                                                                                            accepted
                                                                                                                            successfully
                                                                                                                            < /
                                                                                                                                description>
                                                                                                                                <br>
                                                                                                                                <
                                                                                                                                    pdu>
                                                                                                                                    4
                                                                                                                                    < /
                                                                                                                                        pdu>
                                                                                                                                        <br>
                                                                                                                                        < /
                                                                                                                                            submitResponses>
                                                                                                                                            <br>
                                                                                                                                            < /
                                                                                                                                                submitResponsesList>
                                                                                                                                                <br>
                                                                                                                                                < /
                                                                                                                                                    PushResponse>
                                                                                                                                                    <br>

                    </span>
                </div>
                </div>
                <hr>

                <div class="container-fluid ml-5">
               <h6><span><b> GET DLR Push URL</b></span>  </h6>
               <span>
                http://domainname?txid=##messageid##&to=##mobile##&from=##senderid##&batchid=##custref##&description=##deliverycode##&pdu=##pducount##&rtime=##submittime##
                &ctime=##deliverytime##&dlrStatus=##deliverycode##&errorCode=##status##&smsText=XXXX##messagetext##
                 </span>
                </div>
               <hr>


                    <div class="container-fluid ml-5">
                        <span>
                    http://{{c}}ServerUrl/ServerIp>:{{c}}Port>/pushapi/fe/api/v1/one2Many<br>
                    < OneToManyPush><br>
                        < credentials><br>
                            < apiKey>xxxxxxxxxxx< / apiKey><br>
                                    < user>firstuser< / user><br>
                                            < / credentials><br>
                                                < from>SENDER< / from><br>
                                                        < messageText>XXXXX < /
                                                                messageText><br>
                                                                < options><br>
                                                                    < dltContentId>12345< / dltContentId><br>
                                                                            < / options><br>
                                                                                < recpients><br>
                                                                                    < corelationId>1< / corelationId>
                                                                                            <br>
                                                                                            < mobile>xxxx< /
                                                                                                    mobile><br>
                                                                                                    < / recpients><br>
                                                                                                        < recpients><br>
                                                                                                            <
                                                                                                                corelationId>
                                                                                                                2< /
                                                                                                                    corelationId>
                                                                                                                    <br>
                                                                                                                    <
                                                                                                                        mobile>
                                                                                                                        xxxx
                                                                                                                        < /
                                                                                                                            mobile>
                                                                                                                            <br>
                                                                                                                            < /
                                                                                                                                recpients>
                                                                                                                                <br>
                                                                                                                                <
                                                                                                                                    unicode>
                                                                                                                                    true
                                                                                                                                    < /
                                                                                                                                        unicode>
                                                                                                                                        <br>
                                                                                                                                        < /
                                                                                                                                            OneToManyPush>
                                                                                                                                            <br>


                                                                                                                                            <h6>Response
                                                                                                                                                :-
                                                                                                                                            </h6>
                                                                                                                                            <br>
                                                                                                                                            < ?xml
                                                                                                                                                version="1.0"
                                                                                                                                                encoding="UTF-8"
                                                                                                                                                standalone="yes"
                                                                                                                                                ?>
                                                                                                                                                <br>
                                                                                                                                                <
                                                                                                                                                    PushResponse>
                                                                                                                                                    <br>
                                                                                                                                                    <
                                                                                                                                                        submitResponsesList>
                                                                                                                                                        <br>
                                                                                                                                                        <
                                                                                                                                                            submitResponses>
                                                                                                                                                            <br>
                                                                                                                                                            <
                                                                                                                                                                transactionId>
                                                                                                                                                                XXXXXX
                                                                                                                                                                < /
                                                                                                                                                                    transactionId>
                                                                                                                                                                    <br>
                                                                                                                                                                    <
                                                                                                                                                                        state>
                                                                                                                                                                        SUBMIT_ACCEPTED
                                                                                                                                                                        < /
                                                                                                                                                                            state>
                                                                                                                                                                            <br>
                                                                                                                                                                            <
                                                                                                                                                                                description>
                                                                                                                                                                                Message
                                                                                                                                                                                accepted
                                                                                                                                                                                successfully
                                                                                                                                                                                < /
                                                                                                                                                                                    description>
                                                                                                                                                                                    <br>
                                                                                                                                                                                    <
                                                                                                                                                                                        pdu>
                                                                                                                                                                                        4
                                                                                                                                                                                        < /
                                                                                                                                                                                            pdu>
                                                                                                                                                                                            <br>
                                                                                                                                                                                            < /
                                                                                                                                                                                                submitResponses>
                                                                                                                                                                                                <br>
                                                                                                                                                                                                <
                                                                                                                                                                                                    submitResponses>
                                                                                                                                                                                                    <br>
                                                                                                                                                                                                    <
                                                                                                                                                                                                        transactionId>
                                                                                                                                                                                                       XXXXXX
                                                                                                                                                                                                        < /
                                                                                                                                                                                                            transactionId>
                                                                                                                                                                                                            <br>
                                                                                                                                                                                                            <
                                                                                                                                                                                                                state>
                                                                                                                                                                                                                SUBMIT_ACCEPTED
                                                                                                                                                                                                                < /
                                                                                                                                                                                                                    state>
                                                                                                                                                                                                                    <br>
                                                                                                                                                                                                                    <
                                                                                                                                                                                                                        description>
                                                                                                                                                                                                                        Message
                                                                                                                                                                                                                        accepted
                                                                                                                                                                                                                        successfully
                                                                                                                                                                                                                        < /
                                                                                                                                                                                                                            description>
                                                                                                                                                                                                                            <br>
                                                                                                                                                                                                                            <
                                                                                                                                                                                                                                pdu>
                                                                                                                                                                                                                                4
                                                                                                                                                                                                                                < /
                                                                                                                                                                                                                                    pdu>
                                                                                                                                                                                                                                    <br>
                                                                                                                                                                                                                                    < /
                                                                                                                                                                                                                                        submitResponses>
                                                                                                                                                                                                                                        <br>
                                                                                                                                                                                                                                        < /
                                                                                                                                                                                                                                            submitResponsesList>
                                                                                                                                                                                                                                            <br>
                                                                                                                                                                                                                                            < /
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    PushResponse>
                <br>

                </span>
            </div>
            </div>
        </div>
    </div>



    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous">

        // function myfunction(){
        //     document.querySelector('DATA')
        // }

    </script>
</body>
    </div>
</div>


<!-- </html> -->
