import { Component, HostListener, OnInit,ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ViewTemplatesComponent } from './view-templates/view-templates.component';
import { AddContentTemplateComponent } from './add-content-template/add-content-template.component';
import { UploadTemplateComponent } from './upload-template/upload-template.component';

@Component({
  selector: 'app-content-template-mgmt',
  templateUrl: './content-template-mgmt.component.html',
  styleUrls: ['./content-template-mgmt.component.scss']
})
export class ContentTemplateMgmtComponent implements OnInit {

  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }
page=1;
dataList:any;
  constructor(
    private storageService: StorageService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.checkScreenSize();
  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  @ViewChild(ViewTemplatesComponent, { static: true })
  ViewTemplatesComponent!: ViewTemplatesComponent;
  @ViewChild(AddContentTemplateComponent, { static: true })
  AddContentTemplateComponent!: AddContentTemplateComponent;
  @ViewChild(UploadTemplateComponent, { static: true })
  UploadTemplateComponent!: UploadTemplateComponent;
tt(){
  // window.location.reload()
}
resetChildForm(){
  this.ViewTemplatesComponent.resetAddBlacklistForm();
  this.AddContentTemplateComponent.resetAddBlacklistForm();
  this.UploadTemplateComponent.resetAddBlacklistForm();

}

add(){
  let params = {
    "loggedInUserName": this.storageService.username,
    "pagination": this.page,
    "recordRange": 50
  }
  this.reportService.getAllContentTemplates(params).subscribe(data => {
    this.dataList = data?.data?.contentTemplateList;

    // this.downloadLink = data?.data?.downloadReportLink ? data?.data?.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    this.reportService.viewRefresh(this.dataList)

  })
}
}
