import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { PhonebookService } from 'src/app/services/phonebook/phonebook.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit {


  listOfColumn = [
    {
      title: 'User Blacklist Number',
      compare: (a: any, b: any) => a.userBlackListNumber - b.userBlackListNumber,
      priority: 1
    }
  ];

  addBlacklistForm = this.fb.group({
    mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{12}$')]],
    description: [''],
    operation: "addUserBlacklistNumber",
    loggedInUserName: this.storageService.username
  });
  uploadBlacklistForm =this.fb.group({
    ddescription:'',
    filee:''

  })


  listOfData: any;
  selectedContacts: any;
  downloadLink: any;
  fileList: any[] = [];
  description: string = '';

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  constructor(
    private phonebookService: PhonebookService,
    private storageService: StorageService,
    private utils: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getAllBlacklist();
  }

  downloadFile() {
    this.reportService.downloadFile(this.downloadLink);
  }

  resetAddBlacklistForm() {
    this.fileList=[]
    this.addBlacklistForm.reset();
    this.addBlacklistForm.patchValue({
      operation: "addUserBlacklistNumber",
      loggedInUserName: this.storageService.username,
      description:''
    })
    this.uploadBlacklistForm.reset();
    // this.fileList.splice(0, this.fileList.length)
    this.uploadBlacklistForm.patchValue({
      ddescription:'',

    })
  }

  handleFile() {
    let file = this.fileList[0].name;
    var ext = file.split('.').pop();
    if (ext !== 'txt') {
      this.utils.showErrorMsg("Please upload correct file format, you are uploading ." + ext + " Template File Type option. Please check.");
      return false;
    } else {
      return true;
    }
  }

  blackListUpload() {

    if(this.fileList.length ==0){
      this.utils.showErrorMsg("Please upload required File!")
    }
    let files = this.fileList[0];
    this.handleFile();
    if (!files) {
      return
    }


    let formData: FormData = new FormData();
    formData.append('description', this.description);
    formData.append('file', files);
    formData.append("userName", this.storageService.username);
    formData.append("fileType", files.type);

    this.phonebookService.uploadBlacklist(formData).subscribe(data => {
      if (data.result === 'Success') {
        this.utils.showSuccessMsg(data.message)
        this.description = '';
      } else {
        this.utils.showErrorMsg(data.message)
      }
      this.getAllBlacklist()
    }, err => {
      this.utils.showErrorMsg('Something Went Wrong')
    });


  }


  addBlacklist() {
    if (!this.addBlacklistForm.valid) {
      return;
    }

    this.phonebookService.addBlacklistNumber(this.addBlacklistForm.getRawValue()).subscribe(data => {
      this.getAllBlacklist();
      if (data.result === 'Success') {
        this.resetAddBlacklistForm();
        this.utils.showSuccessMsg(data.message)
      } else {
        this.utils.showErrorMsg(data.message)
      }
    }, err => {
      this.utils.showErrorMsg('Something Went Wrong')
    });


  }

  // blackListreset(){
  //   this.uploadBlacklistForm.reset()
  // }

  deleteGroup() {
    const deleteData = {
      numberToBeRemoved: Array.from(this.selectedContacts.values()),
      loggedInUserName: this.storageService.username,
      operation: "removeUserBlackListNumber",
    }
    this.phonebookService.deleteBlacklist(deleteData).subscribe((response: any) => {
      this.getAllBlacklist();
      if (response.result === 'Success') {
        this.utils.showSuccessMsg(response.message)
      } else {
        this.utils.showErrorMsg(response.message)
      }
    });
  }

  getAllBlacklist() {
    this.phonebookService.getAllBlacklist().subscribe(data => {
      this.listOfData = data.data.userBlackListNumberList;
      this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    })
  }

}
