import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class DataService {
  private editableData = new BehaviorSubject<any>(null);
  
  setEditableData(data: any) {
    this.editableData.next(data);
  }

  getEditableData(): Observable<any> {
    return this.editableData.asObservable();
  }

  updateEditableData(updatedData: any) {
    const currentData = this.editableData.value; // Get the current data array
    const currentIndex = currentData.findIndex((item: any) => item.id === updatedData.id);
    if (currentIndex !== -1) {
      // Create a new array with the updated data
      const updatedArray = [...currentData];
      updatedArray[currentIndex] = updatedData;

      // Emit the new array
      this.editableData.next(updatedArray);
    }
  }
}
