<div nz-row nzJustify="center" nzGutter="48">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
        <h4 nz-typography>Add Blacklist Number</h4>

        <div class="form-input">
            <form nz-form [formGroup]="addBlacklistForm" nzLayout="vertical">
                <nz-form-item>
                    <nz-form-label nzRequired>Mobile Number</nz-form-label>
                    <nz-form-control [nzLg]="{ span: 14 }">
                        <input type="text" nz-input formControlName="mobileNumber" required placeholder="Enter Mobile Number"  />
                    </nz-form-control>
                    <i><small>Note :Enter Number with 91</small></i>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label >Description</nz-form-label>
                    <nz-form-control [nzLg]="{ span: 18 }">
                        <nz-textarea-count>
                            <textarea rows="3" #textArea formControlName="description" placeholder="description" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <button nz-button nzType="primary" (click)="addBlacklist()" [disabled]="!addBlacklistForm.valid">Add Blacklist Number</button>
                <button nz-button (click)="resetAddBlacklistForm()">Reset</button>
            </form>
        </div>

    </div>
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
        <h4 nz-typography>Upload Contact</h4>

        <div class="form-input">
            <form  nz-form  [formGroup]="uploadBlacklistForm" nzLayout="vertical">
                <nz-form-item>
                    <nz-form-label nzRequired>Select File</nz-form-label>
                    <nz-form-control>
                        <nz-upload [(nzFileList)]="fileList" formControlName="filee" [nzBeforeUpload]="beforeUpload">
                            <button nz-button>
                                <span nz-icon nzType="upload"></span>
                                Select File
                            </button>
                        </nz-upload>
                        <i><small>Note : Only .txt allowed</small></i>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label >Description</nz-form-label>
                    <nz-form-control [nzLg]="{ span: 18 }">
                        <nz-textarea-count>
                            <textarea rows="3" #textArea [(ngModel)]="description" formControlName="ddescription" placeholder="description" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <button nz-button nzType="primary" (click)="blackListUpload()">Upload Blacklist</button>

            </form>
        </div>

    </div>
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 8 }">
        <h4 nz-typography>View Blacklist</h4>

        <div nz-row [nzGutter]="24">
            <!-- <div nz-col>
                <button nz-button nzType="default" nzDanger (click)="deleteGroup()">Delete Selected</button>
            </div> -->
            <div nz-col>
                <button nz-button nzType="primary" (click)="downloadFile()" *ngIf="downloadLink">
                    <span nz-icon nzType="download"></span>
                    Export
                </button>
            </div>
        </div>
        <br />
        <app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn" [type]="'blacklist'"
            (deleteTriggred)="selectedContacts = $event" [pagination]="true"></app-simple-table>
    </div>
</div>
