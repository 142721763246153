<nz-layout>
  <nz-sider nzMode="inline"  [nzWidth]="isCollapsed ? '60px' : '200px'" nzTheme="light" *ngIf="isAuthenticated">
    <app-header-logo ></app-header-logo>
    <app-left-nav-webtool [isCollapsed]="isCollapsed" *ngIf="appName === 'mis'"  ></app-left-nav-webtool>
    <!-- <app-left-nav-webtool [isCollapsed]="isCollapsed" *ngIf="appName === 'webtool'"  [sidebarshow]="sidebar"  (click)="onClick()"></app-left-nav-webtool> -->
    <app-left-nav-webtool  [isCollapsed]="isCollapsed" *ngIf="appName === 'webtool'" ></app-left-nav-webtool>
  </nz-sider>
  <nz-layout>
    <nz-header  *ngIf="isAuthenticated">
      <app-header  ></app-header>
    </nz-header>
    <!-- <nz-breadcrumb>
      <nz-breadcrumb-item>User</nz-breadcrumb-item>
      <nz-breadcrumb-item>Bill</nz-breadcrumb-item>
    </nz-breadcrumb> -->
    <!-- {{ $sub | async | json }} -->

    <nz-page-header  [nzTitle]="pageName" *ngIf="isAuthenticated"></nz-page-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet ></router-outlet>
      </div>
    </nz-content>
    <!-- <nz-footer *ngIf="isAuthenticated">VTS © 2022</nz-footer> -->
  </nz-layout>
</nz-layout>
