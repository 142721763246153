import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _storageService: StorageService,
    private _httpClient: HttpClient) { }

  getDashboardData(): Observable<any> {
    let cred = { username: this._storageService.username };
    return this._httpClient.post(environment.dashboardCards, cred);
  }

}
