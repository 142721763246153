import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreditCountPipe } from 'src/app/pipe/credit-count.pipe';
import { BASE_URL, environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private _storageService: StorageService,
    private utilService: UtilsService,
    private creditCountPipe: CreditCountPipe,
    private storageService: StorageService,
    private _httpClient: HttpClient) { }


  sendQuickSMS(params: any): Observable<any> {
    return this._httpClient.post(environment.sendQuickSMS, params);
  }

  deletescheduleCampaignReport(params: any): Observable<any> {
    return this._httpClient.post(environment.deletescheduleCampaignReport, params);
  }

  sendBulkSMS(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadCampaign, params);
  }

  uploadBulkFile(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadFile, params);
  }

  uploadDynamicFile(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadDynamicFile, params);
  }

  sendDynamicSMS(params: any): Observable<any> {
    return this._httpClient.post(environment.dynamicCampaign, params);
  }

  sendGroupSMS(params: any): Observable<any> {
    return this._httpClient.post(environment.groupCampaign, params);
  }

  previewDynamicSMSCampaign(params: any): Observable<any> {
    return this._httpClient.post(environment.dynamicPreview, params);
  }

  genCampaignName() {
    const dTime = this.utilService.formatDate(new Date(), 'ddMMYYYY-HHmm');
    var CName = this.storageService.username + "-campaign-" + dTime;
    return CName;
  }

  validateCampaignForm(formData: any) {
    // let formData = this.quickCampaignForm.getRawValue();
    var type = formData.msgType;
    const charCount = this.creditCountPipe.getCharCount(formData.msgText)
    const isPlainMsg = this.creditCountPipe.isPlainMsg(formData.msgText);

    if (isPlainMsg && (type == "unicode" || type == "FU")) {
      this.utilService.showErrorMsg('Message Type Mismatch, Please select Message Type Plain to send SMS.')
      return;
    } else if (!isPlainMsg && (type == "plain" || type == "FL")) {
      this.utilService.showErrorMsg('Message Type Mismatch, Please select Message Type Unicode to send SMS.')
      return;
    }

    if (isPlainMsg && (charCount > 160) && (formData.msgPart == "single")) {
      this.utilService.showErrorMsg('You are trying to send a Multipart SMS, Please select Message Part as Multipart to send the SMS.')
      return;
    } else if (!isPlainMsg && (charCount > 70) && (formData.msgPart == "single")) {
      this.utilService.showErrorMsg('You are trying to send a Multipart SMS, Please select Message Part as Multipart to send the SMS.')
      return;
    } else if (isPlainMsg && (charCount < 160) && (formData.msgPart == "multi")) {
      this.utilService.showErrorMsg('You are trying to send a Singlepart SMS, Please select Message Part as Singlepart to send the SMS.')
      return;
    } else if (!isPlainMsg && (charCount < 70) && (formData.msgPart == "multi")) {
      this.utilService.showErrorMsg('You are trying to send a Singlepart SMS, Please select Message Part as Singlepart to send the SMS.')
      return;
    }

    return true;
  }

  getCaretPos(oField: any) {
    if (oField.selectionStart || oField.selectionStart == '0') {
      return oField.selectionStart;
    } else {
      return 0;
    }
  }


  getIndex(str: any, substr: any, ind: any) {
    var Len = str.length, i = -1;
    while (ind-- && i++ < Len) {
      i = str.indexOf(substr, i);
      if (i < 0) break;
    }
    return i;
  }

  getMobileNumberCount(event: any) {
    return event?.length > 9 ? event.split('\n').length : 0;
  }

  getTemplatesBySenderId(params: any): Observable<any> {
    return this._httpClient.post(environment.viewContentTemplate, params)
  }

}
