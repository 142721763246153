import { Pipe, PipeTransform } from '@angular/core';
import { PLAIN_TXT_REGEX } from 'src/environments/environment';

@Pipe({
  name: 'creditCount'
})
export class CreditCountPipe implements PipeTransform {
  transform(value: unknown, type?: unknown): unknown {
    if (type === 'checkPart') {
      return this.checkPart(value)
    } else {
      return value ? this.calculateCredit(value) : 0;
    }
  }

  calculateCredit(value: any) {
    let charCount = value ? this.getCharCount(value) : 0;
    var count = charCount;
    var creditCount = 0;
    if (this.isPlainMsg(value)) {
      if (charCount > 160) {
        var n = count / 153;
        var dec = Math.ceil(n)
        creditCount = dec;
      } else if (count != 0 && count <= 160) {
        creditCount = 1;
      } else {
        creditCount = 0;
      }
    } else {
      if (charCount > 70) {
        var n = count / 67;
        var dec = Math.ceil(n)
        creditCount = dec;
      } else if (count != 0 && count <= 70) {
        creditCount = 1;
      } else {
        creditCount = 0;
      }
    }
    return creditCount;
  }

  lengthWithoutNewlines(str: string) {
    return str.replace(/\r/g, '').replace(/\n/g, '').length;
  }

  checkPart(value: any) {
    let messagePart: string = '';
    let charCount = value ? this.getCharCount(value) : 0;
    if (this.isPlainMsg(value)) {
      if (charCount > 160) {
        messagePart = 'multi'
      } else {
        messagePart = 'single'
      }
    } else {
      if (charCount > 70) {
        messagePart = 'multi'
      } else {
        messagePart = 'single'
      }
    }
    return messagePart;
  }

  getCharCount(value: any) {

    var sectionToCheck = value;
    var allFoundCharacters = sectionToCheck.match(/[\[\]\{\}\\|\^€\~]/g);
    var splCharCount = (allFoundCharacters) ? allFoundCharacters.length : 0;//count
    var charCount = this.lengthWithoutNewlines(value) + parseInt(splCharCount);
    return charCount;
    }


  isPlainMsg(value: any) {
    return new RegExp(PLAIN_TXT_REGEX).test(value)
  }
}
