import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss']
})
export class CreditHistoryComponent implements OnInit {


  listOfColumn = [
    {
      title: 'Created Date',
      compare: (a: any, b: any) => a.createdDate - b.createdDate,
      priority: false
    },
    {
      title: 'Credit',
      compare: (a: any, b: any) => a.credit - b.credit,
      priority: 3
    },
    {
      title: 'Status',
      compare: (a: any, b: any) => a.status - b.status,
      priority: 2
    },
    {
      title: 'Updated Credit',
      compare: (a: any, b: any) => a.updatedCredit - b.updatedCredit,
      priority: 1
    },
    {
      title: 'Updated By',
      compare: (a: any, b: any) => a.updatedBy - b.updatedBy,
      priority: 4
    }, {
      title: 'Comment',
      compare: (a: any, b: any) => a.comment - b.comment,
      priority: 5
    }
  ];

  listOfData: any;
  pageNumber: number = 1;
  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  ngOnInit(): void {
    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
    });

    // this.getCreditHistory();
  }

  submitForm(): void {
    let form = this.validateForm.value;
    if (form.rangePicker) {
      this.getCreditHistory(form.rangePicker[0], form.rangePicker[1])
    }
  }

  getCreditHistory(from: any = this.today, to: any = this.today, page: any = this.pageNumber) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to),
      "pageNumber": page
    }

    this.reportService.getCreditHistory(params).subscribe(data => {
      this.listOfData = data.data.grid;
    })
  }

  nextPage() {
    this.pageNumber++;
    this.getCreditHistory();
  }

  previousPage() {
    this.pageNumber--;
    this.getCreditHistory();
  }

  ngOnDestroy() {
  }
}
