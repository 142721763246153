<nz-tabset nzTabPosition="left" *ngIf="!isSmallScreen">
    <nz-tab nzTitle="Create-Url-Link">
        <h4 nz-typography>Create Short URL</h4>
        <br/>
        <app-create-url></app-create-url>
    </nz-tab>
    <nz-tab nzTitle="View-Url-Link">
        <h4 nz-typography>View Short URL</h4>
        <br/>
        <app-view-short></app-view-short>
    </nz-tab>
   
</nz-tabset>
<nz-tabset nzTabPosition="left" *ngIf="isSmallScreen">
    <nz-tab nzTitle="Create-Url-Link">
        <h4 nz-typography>Create Short URL</h4>
        <br/>
        <app-create-url></app-create-url>
    </nz-tab>
    <nz-tab nzTitle="View-Url-Link">
        <h4 nz-typography>View Short URL</h4>
        <br/>
        <app-view-short></app-view-short>
    </nz-tab>
   
</nz-tabset>