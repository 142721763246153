import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-short',
  templateUrl: './view-short.component.html',
  styleUrls: ['./view-short.component.scss']
})
export class ViewShortComponent implements OnInit {

  listOfColumn = [
     {
      title: 'serial-No.',
      // compare: (a: any, b: any) => a.serial no.localeCompare(b.name),
      priority: false
       },
        {
          title: 'Name',
          compare: (a: any, b: any) => a.name.localeCompare(b.name),
          priority: 1
        },
        {
          title: 'Short Code',
          compare: (a: any, b: any) => a.shortCode.localeCompare(b.shortCode),
          priority: 2
        },
        {
          title: 'Callback Url',
          compare: (a: any, b: any) => a.callbackUrl.localeCompare(b.callbackUrl),
          priority: 3
        },
        {
          title: 'Is Active',
          compare: (a: any, b: any) => a.isActive.localeCompare(b.isActive),
          priority: 4
        },
        {
          title: 'Is Dynamic',
          compare: (a: any, b: any) => a.isDynamic - b.isDynamic,
          priority: 5
        },
        // {
        //   title: 'Action',
        //   compare: (a: any, b: any) => a.shortUrlId - b.shortUrlId,
        //   priority: false
        // }
      ];
    
      listOfData: any;
      listActiveHost: any;
      shortCode: string = '';
      viewshortUrl: any;
    
      constructor(
        private fb: FormBuilder,
        private storageService: StorageService,
        private reportService: ReportService,
        private utilService: UtilsService,
        private location: Location
      ) { }
    
      ngOnInit(): void {
        this.loadData();
        this.getActiveDomains();
        this.getAllShortUrl();
      }

      loadData(): void {
        this.getActiveDomains();
        this.getAllShortUrl();
      }
    
      submitForm() {
        if (!this.viewshortUrl.valid) {
          return;
        }
    
        let formData = this.viewshortUrl.getRawValue();
        if (formData.callbackUrlValue !== "") {
          formData.callbackUrl = formData.callbackUrl + formData.callbackUrlValue;
        }
    
        if (formData.longUrlValue !== "") {
          formData.longUrl = formData.longUrl + formData.longUrlValue;
        }
    
        delete formData.callbackUrlValue;
        delete formData.longUrlValue;
        delete formData.shortCode;
    
        this.reportService.addviewshortUrl(formData).subscribe(data => {
          this.getAllShortUrl();
          if (data.result === "Success") {
            this.utilService.showSuccessMsg(data.message);
            this.getAllShortUrl();
          } else {
            this.utilService.showErrorMsg(data.message);
            this.shortCode = data.data.shortCode;
          }
        }, err => {
          this.utilService.showErrorMsg('Something Went Wrong');
        });
      
      }
    
    
      deleteShortUrl(data: any) {
        let params = { "loggedInUserName": "airteltesting", "shortUrlId": data.shortUrlId, "operation": "removeShortUrlFromList" };
        this.reportService.deleteShortUrl(params).subscribe(data => {
          this.getAllShortUrl();
          if (data.result === "Success") {
            this.utilService.showSuccessMsg(data.message);
          } else {
            this.utilService.showErrorMsg(data.message);
          }
        })
      }
    
      getAllShortUrl() {
        this.reportService.listAllShortUrl().subscribe(data => {
          this.listOfData = data.data.shortUrlList;
        })
      }
      getActiveDomains() {
        this.reportService.getActiveDomain().subscribe((data: any) => {
          this.listActiveHost = data?.data.hostNameList;
        })
      }
    }
    

