<div class="form-input">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" nzLayout="vertical">
    <div nz-row [nzGutter]="24" nzAlign="middle">
      <div nz-col>
        <nz-form-item>
          <nz-form-label nzRequired>Select Range</nz-form-label>
          <nz-form-control>
            <nz-range-picker formControlName="rangePicker" [nzDisabledDate]="disabledDate"
            [nzRanges]="ranges" [nzFormat]="'dd-MM-yyyy'" (ngModelChange)="onChange($event)">
          </nz-range-picker>
                
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="{ span: 24}" [nzLg]="{ span: 6}">
        <nz-form-item>
          <nz-form-label nzRequired>Campaign Name</nz-form-label>
          <nz-form-control>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select campaign"
              formControlName="campaignName" ngModel="All">
              <nz-option nzLabel="All" nzValue="All"></nz-option>
              <nz-option [nzLabel]="item.campaignName" [nzValue]="item.campaignId"
                *ngFor="let item of listCampaignNames"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col>
        <nz-form-control>
          <button nz-button nzType="primary" [disabled]="validateForm.invalid">Submit</button>
        </nz-form-control>
      </div>
    </div>
  </form>
</div>


<app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn" [pagination]="true"></app-simple-table>