<div class="gradient-background" style="height: 100vh;">
<div class="context">
    <div nz-row >
        <div nz-col [nzXs]="{ span: 24}" [nzLg]="{ span: 6,offset:9}">
            <div class="form-login" style="margin-top: 65%;">
                <!-- <img src="assets/images/vts-logo.png" style="width: 100%;" /> -->
                <!-- <div style="background: #ECECEC;padding:30px;"> -->
                    <nz-card style="background-color:#ffffff;"  [nzBordered]="true" [nzExtra]="extraTemplate">
                <form nz-form [formGroup]="validateForm" class="form" (ngSubmit)="submitForm()" *ngIf="loginbox">
                    <nz-form-item style="background-color: #ffffff;">
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group nzPrefixIcon="user" nzSize="large">
                                <input type="text" nz-input [(ngModel)]="inputValue" formControlName="username" placeholder="Username" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please input your Password!">
                            <nz-input-group nzPrefixIcon="lock" nzSize="large">
                                <input type="password" nz-input [(ngModel)]="inputValue1" formControlName="password" placeholder="Password" />
                            </nz-input-group>
                        </nz-form-control>
                        
                    </nz-form-item>
                   




                        <!-- <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                            <nz-form-item>
                              <nz-form-control nzErrorTip="Please input your OTP!">
                                <nz-input-group nzPrefixIcon="user">
                                  <input type="text" nz-input formControlName="userName" placeholder="Username" />
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                           
                            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Log in</button>
                            Or
                            <a>register now!</a>
                         -->


                         <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" 
                         nzSize="large">Log in</button>
                       
                 </form>
                          <form nz-form [formGroup]="otpform" class="login-form" (ngSubmit)="verifyotp()"  *ngIf="showOtpBox">
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group nzPrefixIcon="user" nzSize="large">
                                        <input type="text" nz-input [(ngModel)]="inputValue" (input)="onInputChange()" formControlName="username" placeholder="Username" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Please input your Password!">
                                    <nz-input-group nzPrefixIcon="lock" nzSize="large">
                                        <input type="password" nz-input [(ngModel)]="inputValue1" (input)="onInputChange1()" formControlName="password" placeholder="Password" />
                                    </nz-input-group>
                                </nz-form-control>
                                
                            </nz-form-item>
                            <nz-form-item>
                        <nz-form-control nzSize="large" nzErrorTip="Please input your OTP!">
                            <nz-input-group nzPrefixIcon="lock" nzSize="large">
                                <input type="otp"  nz-input formControlName="userOtp" placeholder="userOtp" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                        <button nzSize="large"  nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
                        nzSize="large">Verify OTP</button>
                    </form>
                       
                
                </nz-card>
                </div>
            </div>
        </div>
    </div>
</div>
   

<!-- </div> -->