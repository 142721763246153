<nz-tabset nzTabPosition="left" *ngIf="!isSmallScreen">
    <nz-tab nzTitle="Add" (nzClick)="tt(); resetChildForm()" >
        <h4 nz-typography>Add Content Template</h4>
        <br/>
        <app-add-content-template ></app-add-content-template>
    </nz-tab>
    <nz-tab nzTitle="Upload" (nzClick)="add(); resetChildForm()" >
        <h4 nz-typography>Upload Content Template</h4>
        <br/>
        <app-upload-template></app-upload-template>
    </nz-tab>
    <nz-tab nzTitle="View" (nzClick)="add(); resetChildForm()" >
        <h4 nz-typography>View Content Template</h4>
        <br/>
        <app-view-templates (myOutput)="add()"></app-view-templates>
    </nz-tab>
</nz-tabset>

<nz-tabset *ngIf="isSmallScreen">
    <nz-tab nzTitle="Add" (nzClick)="tt(); resetChildForm()" >
        <h4 nz-typography>Add Content Template</h4>
        <br/>
        <app-add-content-template ></app-add-content-template>
    </nz-tab>
    <nz-tab nzTitle="Upload" (nzClick)="add(); resetChildForm()" >
        <h4 nz-typography>Upload Content Template</h4>
        <br/>
        <app-upload-template></app-upload-template>
    </nz-tab>
    <nz-tab nzTitle="View" (nzClick)="add(); resetChildForm()" >
        <h4 nz-typography>View Content Template</h4>
        <br/>
        <app-view-templates (myOutput)="add()"></app-view-templates>
    </nz-tab>
</nz-tabset>
