import { Component } from '@angular/core';

import jsPDF from 'jspdf';
// import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent {
  a:any =`{`;
  b:any =`}`;
  c:any =`<`;


  // DATA:any;


  htmltoPDF()



  {

  let DATA: any = document.querySelector('#parentdiv');
    // parentdiv is the html element which has to be converted to PDF
    // html2canvas(document.querySelector("#parentdiv")).then(canvas => {
      html2canvas(DATA).then((canvas) => {
      var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      var pageHeight= pdf.internal.pageSize.height;
      console.log(pageHeight)
      var imgData  = canvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(imgData,0,0,canvas.width, canvas.height);
      pdf.save('Documentation.pdf');


      

  });

}
}



