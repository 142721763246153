import { Component, Input, HostListener, OnInit,Output } from '@angular/core';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-left-nav-webtool',
  templateUrl: './left-nav-webtool.component.html',
  styleUrls: ['./left-nav-webtool.component.scss']
})
export class LeftNavWebtoolComponent implements OnInit {
  // sidebarshow:any;
userType:any='';
  @Input() isCollapsed: boolean = false;
 showCreditHistory: boolean = false;
 @Input() sidebarshow = "";
 logoUrl:any = '';
 isSmallScreen = false;
 @HostListener('window:resize', ['$event'])
 onResize(event: Event): void {
   this.checkScreenSize();
 }
 constructor(
  private storageService: StorageService
) {
  if (localStorage.getItem('role')) {
    this.userType = localStorage.getItem('role');
    this.logoUrl = sessionStorage.getItem('logoUrl'); // Corrected to use 'logoUrl' as the key
  }
}

//    {  if(sessionStorage.getItem('logoUrl')) {
//     this.logoUrl = sessionStorage.getItem('logoUrl');
//   }
// }

  ngOnInit(): void {
    this.checkScreenSize();
    let userAccess = this.storageService.userPrivilage;
    this.showCreditHistory = userAccess.includes('SHOW_CREDIT_HISTORY');
  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }
  
}
