import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-clicker-report',
  templateUrl: './clicker-report.component.html',
  styleUrls: ['./clicker-report.component.scss']
})
export class ClickerReportComponent implements OnInit {

  listOfColumn = [
    {
      title: 'Campaign Name',

      compare: (a: any, b: any) => a.campaignName.localeCompare(b.campaignName),
      priority: false
    },
    {
      title: 'Child Short Url',
      compare: (a: any, b: any) => a.childShortUrl.localeCompare(b.childShortUrl),
      priority: false
    },
    {
      title: 'Mobile Number',
      compare: (a: any, b: any) => a.mobileNumber - b.mobileNumber,
      priority: false
    },
    {
      title: 'Created Date',

      compare: (a: any, b: any) => a.createdDate.localeCompare(b.createdDate),
      priority: false
    },
    {
      title: 'User IP Address',
      compare: (a: any, b: any) => a.userIpAddress - b.userIpAddress,
      priority: false
    }
  ];

  listOfData: any;
  downloadLink: any;
  pageNumber: number = 1;
  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  ngOnInit(): void {
    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
      mobile:''
    });

    // this.getClickerReport();
  }

  onChange(result: Date[]): void {
    console.log('From: ', result[0], ', to: ', result[1]);
  }

  submitForm(): void {
    let form = this.validateForm.value;
    if (form.rangePicker) {
      this.getClickerReport(form.rangePicker[0], form.rangePicker[1])
    }
  }

  getClickerReport(from: any = this.today, to: any = this.today, page: any = this.pageNumber) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to),
      "mobileNumber":this.validateForm.get('mobile')?.value,
      "pageNumber": page
    }

    this.reportService.getClickerReport(params).subscribe(data => {
      this.listOfData = data.data.grid;
      this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    })
  }

  nextPage() {
    this.pageNumber++;
    this.getClickerReport();
  }

  previousPage() {
    this.pageNumber--;
    this.getClickerReport();
  }

  downloadFile() {
    this.reportService.downloadFile(this.downloadLink);
  }

  ngOnDestroy() {
  }
}
