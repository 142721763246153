import { Component, OnInit ,HostListener} from '@angular/core';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnInit {

  logoUrl: any;
  isSmallScreen = false;
  isCollapsed = true;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }
  
  constructor(
    private storageService: StorageService
  ) {
    if (localStorage.getItem('role')) {
      this.logoUrl = sessionStorage.getItem('logoUrl'); // Corrected to use 'logoUrl' as the key
    }
  }

  ngOnInit(): void {
    // this.logoUrl = sessionStorage.getItem('logoUrl');
  }
  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

}
