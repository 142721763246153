import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-clicker',
  templateUrl: './clicker.component.html',
  styleUrls: ['./clicker.component.scss']
})
export class ClickerComponent implements OnInit {

  listOfColumn = [
    {
      title: 'Campaign Name',
      compare: (a: any, b: any) => a.campaignName - b.campaignName,
      priority: 1
    },
    {
      title: 'summaryDate',
      compare: (a: any, b: any) => a.childShortUrl - b.childShortUrl,
      priority: 3
    },
    {
      title: 'totalClicks',
      compare: (a: any, b: any) => a.mobileNumber - b.mobileNumber,
      priority: 2
    },
    {
      title: 'uniqueClicks',
      compare: (a: any, b: any) => a.createdDate - b.createdDate,
      priority: 5
    },
   
  ];


  listCampaignNames: any;
  listOfData: any;
  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private reportService: ReportService) { }


    disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;


    ngOnInit(): void {
    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
      campaignName: [null]
    });
    this.getCampaingNameList();
  }
  getCampaingNameList(from: any = this.today, to: any = this.today) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to)
    }

    this.reportService.getCampaignReport(params).subscribe(data => {
      this.listCampaignNames = data.data.grid;
    })
  }

    // this.viewGeneratedReport();
  //   this.getCampaingNameList();
  // }

  
  onChange(result: Date[]): void {
    console.log('onChange: ', result);
    this.getCampaingNameList(result[0], result[1])
  }


  submitForm(): void {
    let form = this.validateForm.value;
    if (form.rangePicker) {
      this.clickerreportAnalytii(form.rangePicker[0], form.rangePicker[1])
      let params = {
        campaignId: form.campaignName,
        campaignName: this.listCampaignNames.find((data: any) => data.campaignId === form.campaignName).campaignName,
        extension: 'csv'
      }
     
    }
  }
  

  clickerreportAnalytii(from: any = this.today, to: any = this.today, ) {
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(from),
      "toDate": this.utilsService.formatDate(to),
      "campaignName":this.validateForm.get('campaignName')?.value,
     
    }

    this.reportService.clickerreportAnalytii(params).subscribe(data => {
      this.listOfData = data.data.grid;
      // this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    })
  }


  // submitForm(): void {
  //   let form = this.validateForm.value;
  //   if (form.rangePicker) {
  //     let params = {
  //       campaignId: form.campaignName,
  //       campaignName: this.listCampaignNames.find((data: any) => data.campaignId === form.campaignName).campaignName,
  //       extension: 'csv'
  //     }
  //     this.generateReport(form.rangePicker[0], form.rangePicker[1], params)
  //   }
  // }

  // getCampaingNameList(from: any = this.today, to: any = this.today) {
  //   let params = {
  //     "loggedInUserName": this.storageService.username,
  //     "fromDate": this.utilsService.formatDate(from),
  //     "toDate": this.utilsService.formatDate(to)
  //   }

    // this.reportService.clickerreportAnalytii(params).subscribe(data => {
      // this.listClicker = data.data.grid;
  //   })
  // }  

  // viewGeneratedReport(from: any = this.today, to: any = this.today, page: any = this) {
  //   let params = {
  //     "username": this.storageService.username,
  //     "fromDate": this.utilsService.formatDate(from),
  //     "toDate": this.utilsService.formatDate(to),
      // campaignName: '',
      // campaignId: '',
      // pageNumber: page
    // }

    // this.reportService.getGeneratedReports(params).subscribe(data => {
    //   this.listOfData = data.data.grid;
    // })
  // }

  // generateReport(from: any = this.today, to: any = this.today, extras?: any) {
  //   let params = {
  //     "username": this.storageService.username,
  //     "fromDate": this.utilsService.formatDate(from),
  //     "toDate": this.utilsService.formatDate(to)
  //   }
  //   var body = { ...params, ...extras }; // this is the answer

    // this.reportService.clickerreportAnalytii(body).subscribe(data => {
    //   // this.listCampaignNames = data.data.grid;
    //   this.message.create('success', data.message);
    //   this.viewGeneratedReport();
    // })
  // }

 


  // downloadFile() {
  //   this.reportService.downloadFile(this.downloadLink);
  // }

  ngOnDestroy() {
  }


}
