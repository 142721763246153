import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PhonebookService {

  constructor(private _storageService: StorageService,
    private utilServie: UtilsService,
    private _httpClient: HttpClient) { }

  getAllPhoneBookGroup(): Observable<any> {
    let cred = { loggedInUserName: this._storageService.username };
    return this._httpClient.post(environment.getAllPhoneBookGroup, cred);
  }

  createGroup(params: any): Observable<any> {
    return this._httpClient.post(environment.addPhoneBookGroup, params)
  }

  deleteGroup(params: any): Observable<any> {
    return this._httpClient.post(environment.deletePhoneBookGroup, params)
  }

  deleteContacts(params: any): Observable<any> {
    return this._httpClient.post(environment.removeNumberFromGroup, params)
  }

  deleteBlacklist(params: any): Observable<any> {
    return this._httpClient.post(environment.removeNumberFromUserBlackList, params)
  }

  addBlacklistNumber(params: any): Observable<any> {
    return this._httpClient.post(environment.addNumberToBlackList, params)
  }

  addContact(params: any): Observable<any> {
    return this._httpClient.post(environment.addNewContacts, params);
  }

  uploadContact(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadNumberInUserGroup, params);
  }

  uploadBlacklist(params: any): Observable<any> {
    return this._httpClient.post(environment.uploadBlackList, params);
  }

  getNumbersOfGroup(params: any): Observable<any> {
    return this._httpClient.post(environment.searchGroupNumber, params);
  }

  getAllBlacklist(): Observable<any> {
    let cred = { loggedInUserName: this._storageService.username, operation: "getAllBlackListNumbersForUser" };
    return this._httpClient.post(environment.getAllBlacklistNumbersForUser, cred);
  }

  listAllContacts(groupId?: any): Observable<any> {
    let cred = {
      loggedInUsername: this._storageService.username,
      operation: "getAllNumbersInTheGroup",
      groupId: groupId
    };
    return this._httpClient.post(environment.listContacts, cred);
  }

}
