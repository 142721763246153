<div class="form-input">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <div nz-row [nzGutter]="24">
            <div nz-col>
                <nz-form-item>
                    <nz-form-label nzRequired>Select Range</nz-form-label>
                    <nz-form-control>
                        <!-- [nzDefaultPickerValue]="today"  -->
                        <nz-range-picker formControlName="rangePicker" [nzDisabledDate]="disabledDate"
                            [nzRanges]="ranges" nzFormat="yyyy-MM-dd">
                        </nz-range-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col>
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button nzType="primary">Submit</button>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col *ngIf="downloadLink">
                <button nz-button nzType="primary" (click)="downloadFile()">
                    <span nz-icon nzType="download"></span>
                    Download
                </button>
            </div>
        </div>
    </form>
</div>

<app-simple-table [listOfData]="listOfData" [total]="total" [listOfColumn]="listOfColumn" [pagination]="true"></app-simple-table>

